import { createSelector } from '@reduxjs/toolkit';

export const callbackMapSelector = ({ callbacks }) => callbacks.callbackMap;
export const callbackSelector = id => ({ callbacks }) => callbacks.callbackMap[id];

export const callbacksSelector = createSelector(
    (callbackMapSelector),
    (callbackMap) => {
        return Object.values(callbackMap);
    }
);