import {useShallowEqualSelector} from "../index";

/**
 * Returns the supporters data
 */
export const useSupportersSelector = () => {
    return useShallowEqualSelector(({ supporters: { turf, supporters, turfMap } }) => {
        return {
            turf,
            supporters,
            turfs: Object.keys(turfMap).map(turfId => turfMap[turfId])
        };
    });
};