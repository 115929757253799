import React from 'react';

import SignatureItem from "./item";

import './index.css';

const SignaturesPanel = ({signatures}) => {
    return (
        <div className="signatures-container flex flex1">
            {signatures && signatures.map((signature, index) => (
                <SignatureItem key={`signature-${index}`} signature={signature} />
            ))}
        </div>
    );
};

export default SignaturesPanel;