import React from 'react';

import {useSupportRecordDetailsSelector} from "../../../../../../redux/supportRecords/selectors";

const SignatureItem = ({signature}) => {
    const supportRecord = useSupportRecordDetailsSelector(signature.id);

    let { dataUrl, data: { firstName, lastName, address } } = signature;
    if (Boolean(supportRecord)) {
        firstName = supportRecord.firstName;
        lastName = supportRecord.lastName;
        address = supportRecord.address;
    }

    return (
        <div className="flex column signature-container">
            <div className="signature-name montserat">
                {`${firstName} ${lastName}`}
            </div>
            <div className="signature-address montserat">
                {`${address.line1}`}
            </div>
            <div className="flex1 signature-display" style={{ backgroundImage: `url(${dataUrl ? dataUrl : ""})` }} />
        </div>
    );
};

export default SignatureItem;