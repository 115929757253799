import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import FullscreenDialog from "../../../_common/dialog/fullScreen";

import logo from '../../../../img/tce-horiz-logo-color-white-text-white-outline@2x.png';

import './index.css';

const PrivacyPolicyDialog = ({ showPrivacyPolicy, setShowPrivacyPolicy }) => (
    <FullscreenDialog
        open={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(false)}>
        <div className="flex column flex1 relative">
            <AppBar position="sticky">
                <Toolbar>
                    <div className="flex flex1 add-supporter-toolbar-container">
                        <div className="flex1 alignLeft flex alignCenter">
                            <img src={logo} className="home-toolbar-logo" alt="tce logo" />
                        </div>
                        <div>
                            <Button variant="text" onClick={() => setShowPrivacyPolicy(false)}>I Agree</Button>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="privacy-content-container flex1 montserat padding25">
                <h1>Privacy Policy</h1>
                <p>
                    Texas Campaign for the Environment engages in education, advocacy, and informed participation in civic, community and environmental affairs. We believe that our supporters must be assured that the information concerning them is used responsibly. We have implemented the following policy to protect the privacy of Texas Campaign for the Environment members, contributors and other supporters.
                </p>
                <h2><i>Personal Information</i></h2>
                <p>
                    <i>Why does Texas Campaign for the Environment ask for my information?</i>
                </p>
                <p>
                    You will be asked to provide some personal information in order to participate in our advocacy work. This could be in connection with any one or combination of circumstances, including but not limited to the following:
                </p>
                <ul>
                    <li>
                        Signing up as a supporter of the organization
                    </li>
                    <li>
                        Sending personalized communications to government or corporate officials
                    </li>
                    <li>
                        Distribution of publications and informational materials, by mail and/or electronically
                    </li>
                    <li>
                        Allowing Texas Campaign for the Environment to contact you in the future
                    </li>
                    <li>
                        Making a donation
                    </li>
                </ul>
                <p>
                    <i>What information does Texas Campaign for the Environment collect?</i>
                </p>
                <p>
                    We do not collect personal information from supporters other than what you provide us on a voluntary basis. We ask for your full name and physical address in order to determine which elected officials represent you at the Capitol and City Hall, so you can hold them accountable. We ask for your phone number and email address so that we can provide you with regular updates and action alerts that allow you to stay involved in our advocacy efforts.
                </p>
                <p>
                    <i>How will Texas Campaign for the Environment use the information I provide?</i>
                </p>
                <p>
                    The primary purpose of collecting your personal information is to hold public and corporate officials accountable to your concerns regarding a clean and healthy environment. For example, we keep maintain records indicating how many supporters we currently have in each local, state or federal district. The only personal information we ever share is your name and physical address when we send your letters and/or electronic petitions to government and/or corporate officials as part of our advocacy efforts.
                </p>
                <p>
                    We make every effort to insure the secure collection and transmission of sensitive personal information using industry accepted data collection and encryption methodologies. We do not sell or otherwise disclose such information outside the organization, except as noted above.
                </p>
                <h2><i>Opting Out</i></h2>
                <p>
                    If you do not want certain information to be collected or used by Texas Campaign for the Environment, you can simply choose not to provide that information or otherwise “opt out” of the action or request. Under certain circumstances, opting out may prevent your participation in activities for which personal information is needed, such as involvement in online actions, distribution of publications, action alerts, notices of volunteer opportunities, member benefits, community events, etc.
                </p>
                <p>
                    <i>How do I opt out or change my information?</i>
                </p>
                <p>
                    All Texas Campaign for the Environment members and supporters have the right to view, change or erase the personal information that we have regarding them upon request. Anyone who no longer wishes to receive communications from the organization should contact us immediately, being sure to provide your full information so that we can implement your “opt out” request and send you confirmation once this action has been taken. We can be reached for any of these requests in the following ways:
                </p>
                <p>
                    <u>By postal mail:</u><br/>
                    Texas Campaign for the Environment<br/>
                    105 W. Riverside #120<br/>
                    Austin, TX 78704
                </p>
                <p>
                    <u>By phone:</u><br/>
                    Call us at: 512-326-5655
                </p>
                <p>
                    <u>By email:</u><br/>
                    Send e-mail to: zac(at)texasenvironment(dot)org
                </p>
                <h2>
                    <i>Policy Revision</i>
                </h2>
                <p>
                    We reserve the right to revise this policy periodically. If we make substantive changes in the way we share, store, or handle personally identifiable information, we will notify our supporters through prominent postings on our website and/or by e-mail. A current version of this policy will always be readily accessible on our website, <b>https://www.texasenvironment.org/privacy-policy/</b>. This policy was last revised on October 8, 2018.
                </p>
            </div>
        </div>
    </FullscreenDialog>
);

export default PrivacyPolicyDialog;