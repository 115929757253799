import React from 'react';

import TCEDialog from "../../../_common/dialog";

const ContributionErrorDialog = ({ showContributionError, setShowContributionError }) => (
    <TCEDialog
        open={showContributionError}
        onClose={() => setShowContributionError(false)}
        title="Hold up, y'all"
        actions={[
            {label: 'Got it', onClick: () => { setShowContributionError(false); }}
        ]}>
        Please enter a contribution amount.
    </TCEDialog>
);

export default ContributionErrorDialog;