import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";

import CampaignItem from "./item";
import Stats from "../stats";
import Scoty from "../scoty";
import Loading from "../_common/loading";
import FullscreenDialog from "../_common/dialog/fullScreen";
import {loadCampaigns} from "../../redux/campaigns";
import {campaignsSelector} from "../../redux/campaigns/selectors";

import './index.css';
import OrganizationSelect from "../_common/organizationSelect";
import useFilterCampaigns from "../../hooks/useFilterCampaigns";

const Campaigns = () => {
    const allCampaigns = useSelector(campaignsSelector);
    const dispatch = useDispatch();
    const [orgId, setOrgId] = useState('');
    const campaigns = useFilterCampaigns(allCampaigns, orgId);

    useEffect(() => {
        dispatch(loadCampaigns());
    }, [dispatch]);

    return (
        <div className="campaigns-container">
            {!campaigns ?
                <Loading size={45} />
                :
                <div>
                    <div className="page-header flex items-end">
                        <div>Choose a Campaign</div>
                        <div className="ml-5 w-80">
                            <OrganizationSelect
                                value={orgId}
                                onChange={e => {
                                    setOrgId(e.target.value);
                                }}
                                showEmpty />
                        </div>
                    </div>
                    <div className="marginBottom30">
                        {campaigns && campaigns.length ?
                            campaigns.map(campaign => (
                                <CampaignItem campaignId={campaign.id} key={`campaign-item-${campaign.id}`} />
                            ))
                            :
                            <div className="mt-20 text-center">
                                No campaigns.
                            </div>
                        }
                    </div>
                </div>
            }
            <FullscreenDialog onClose={() => {}} open={Boolean(document.location.pathname.match("stats"))}>
                <Stats />
            </FullscreenDialog>
            <FullscreenDialog onClose={() => {}} open={Boolean(document.location.pathname.match("scoty"))}>
                <Scoty />
            </FullscreenDialog>
        </div>
    )
};

export default Campaigns;