import { createSelector } from "@reduxjs/toolkit";

import {useShallowEqualSelector} from "../index";

const todoMapSelector = state => state.todos.todoMap;
export const todoSelector = todoId => state => state.todos.todoMap[todoId];
export const lastUpdatedSelector = state => state.todos.lastUpdated;
export const colorVisibilitySelector = state => state.todos.colorVisibility;

export const todosSelector = createSelector(
    (todoMapSelector),
    (todoMap) => {
        return Object.values(todoMap);
    }
);

export const todoColorMapSelector = createSelector(
    (todoMapSelector),
    (todoMap) => {
        return Object.keys(todoMap).reduce((ret, todoId) => ({ ...ret, [todoId]: todoMap[todoId].color }), {});
    }
);


/**
 * Returns the todos data
 */
export const useTodosSelector = () => {
    return useShallowEqualSelector(({ todos: { todoMap, todoIds, colorVisibility, lastUpdated } }) => {
        return {
            todos: todoIds ? todoIds.map(id => todoMap[id]) : null,
            colorVisibility,
            lastUpdated,
            todoColors: (todoIds || []).reduce((ret, id) => {
                ret[id] = todoMap[id].color;
                return ret;
            }, {})
        };
    });
};

export const useTodoSelector = (todoId) => {
    return useShallowEqualSelector(({ todos: { todoMap } }) => {
        return todoMap[todoId];
    });
};