/*global google*/
import React, { useState, useEffect, useMemo } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CloseIcon from '@material-ui/icons/Close';
import SortIcon from '@material-ui/icons/Sort';
import FilterIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallbackDetails from "./details";
import {useSelector} from "react-redux";
import {callbacksSelector} from "../../../redux/callbacks/selectors";
import {compareAsc} from "date-fns";
import CallbackItem from "./item";

const SORTBY = {
    CREATED: "created",
    DISTANCE: "distance",
    STREET: "street",
    ADDRESS: "address"
};

const FILTERBY = {
    ALL: "all",
    EVENS: "evens",
    ODDS: "odds"
};

// Interval for getting fresh location and when distance sort is selected
const LOCATION_UPDATE_INTERVAL = 5 * 60 * 1000;

const Callbacks = ({ onClose, editId, setEditId, centerMap }) => {
    const [sortBy, setSortBy] = useState(SORTBY.CREATED);
    const [sortDirection, setSortDirection] = useState(1);
    const [filterBy, setFilterBy] = useState("all");
    const [locationCancel, setLocationCancel] = useState(-1);
    const [location, setLocation] = useState(null);
    const callbacks = useSelector(callbacksSelector);

    useEffect(() => {
        if (sortBy === SORTBY.DISTANCE && locationCancel === -1) {
            const getPosition = () => {
                navigator.geolocation.getCurrentPosition(position => {
                    const {coords: {latitude, longitude}} = position;
                    setLocation({lat: latitude, lng: longitude});
                });
            };

            getPosition();
            const cancel = window.setInterval(getPosition, LOCATION_UPDATE_INTERVAL);

            console.log("cancel", cancel);
            setLocationCancel(cancel);
        } else if (sortBy !== SORTBY.DISTANCE && locationCancel !== -1) {
            console.log("changed sortBy, cancelling interval");
            window.clearInterval(locationCancel);
            setLocationCancel(-1);
        }

        return () => {
            console.log("CLEANUP");
            if (locationCancel !== -1) {
                window.clearInterval(locationCancel);
                setLocationCancel(-1);
            }
        }

    }, [sortBy, locationCancel]);

    // Whenever sortBy changes, we should reset the sort direction to default of Asc
    useEffect(() => {
        setSortDirection(1);
    }, [sortBy]);

    const filteredCallbacks = useMemo(() => {
        switch (filterBy) {
            case FILTERBY.ODDS:
                return callbacks.filter(callback => parseInt(callback.houseNumber, 10) % 2 !== 0);
            case FILTERBY.EVENS:
                return callbacks.filter(callback => parseInt(callback.houseNumber, 10) % 2 === 0);
            default:
            case FILTERBY.ALL:
                return callbacks;

        }
    }, [callbacks, filterBy]);

    const sortedCallbacks = useMemo(() => {
        console.log("sortedCallbacks useMemo");
        switch (sortBy) {
            case SORTBY.DISTANCE:
                if (location) {
                    return filteredCallbacks.sort((c1, c2) => {
                        const d1 = google.maps.geometry.spherical.computeDistanceBetween(
                            new google.maps.LatLng(c1.address.lat, c1.address.lng),
                            new google.maps.LatLng(location.lat, location.lng));

                        const d2 = google.maps.geometry.spherical.computeDistanceBetween(
                            new google.maps.LatLng(c2.address.lat, c2.address.lng),
                            new google.maps.LatLng(location.lat, location.lng));

                        if (d1 < d2) {
                            return sortDirection === 1 ? -1 : 1;
                        } else if (d1 > d2) {
                            return sortDirection === 1 ? 1 : -1;
                        }
                        return 0;
                    })
                }
                return filteredCallbacks;
            case SORTBY.STREET:
                return filteredCallbacks.sort((c1, c2) => {
                    return sortDirection * c1.streetName.localeCompare(c2.streetName);
                });
            case SORTBY.ADDRESS:
                return filteredCallbacks.sort((c1, c2) => {
                    return sortDirection * c1.address.line1.localeCompare(c2.address.line1);
                });
            case SORTBY.CREATED:
            default:
                return filteredCallbacks.sort((c1, c2) => {
                    return sortDirection * compareAsc(new Date(c1.createdAt), new Date(c2.createdAt));
                });
        }
    }, [sortBy, filteredCallbacks, location, sortDirection]);

    return (
        <div className="bg-[#FAFAFA] h-full overflow-y-scroll">
            <div className="p-4">
                {Boolean(editId) ?
                    <CallbackDetails callbackId={editId} onClose={() => setEditId(null)} /> :
                    <>
                        <div className="flex">
                            <div>
                                <IconButton onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className="flex-1 text-center flex items-center justify-center text-xl font-bold">
                                Callbacks
                            </div>
                            <div>
                                <Button variant="contained" color="secondary" onClick={() => setEditId(-1)}>
                                    + Add
                                </Button>
                            </div>
                        </div>
                        <div className="flex">
                            <Accordion className="w-full">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <div className="flex">
                                        <div className="mr-8 flex items-center">
                                            <SortIcon className="mr-1"/> Sort
                                        </div>
                                        <div className="flex items-center">
                                            <FilterIcon className="mr-1" /> Filter
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <div className="flex">
                                            <div className="flex items-center mr-2">
                                                <SortIcon />
                                            </div>
                                            <div>
                                                <ToggleButtonGroup
                                                    value={sortBy}
                                                    exclusive
                                                    size="small"
                                                    onChange={(event, newSortBy) => {
                                                        if (newSortBy) {
                                                            setSortBy(newSortBy);
                                                        } else {
                                                            setSortDirection(-1 * sortDirection);
                                                        }
                                                    }}
                                                    aria-label="text alignment"
                                                >
                                                    <ToggleButton value={SORTBY.CREATED} aria-label="Created">
                                                        Created
                                                    </ToggleButton>
                                                    <ToggleButton value={SORTBY.DISTANCE} aria-label="Distance">
                                                        Distance
                                                    </ToggleButton>
                                                    <ToggleButton value={SORTBY.STREET} aria-label="Street Name">
                                                        Street
                                                    </ToggleButton>
                                                    <ToggleButton value={SORTBY.ADDRESS} aria-label="Address">
                                                        Address
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                        <div className="flex mt-4">
                                            <div className="flex items-center mr-2">
                                                <FilterIcon />
                                            </div>
                                            <div>
                                                <ToggleButtonGroup
                                                    value={filterBy}
                                                    exclusive
                                                    size="small"
                                                    onChange={(event, newFilterBy) => setFilterBy(newFilterBy)}
                                                    aria-label="Filter By">
                                                    <ToggleButton value="all" aria-label="All">
                                                        All
                                                    </ToggleButton>
                                                    <ToggleButton value="evens" aria-label="Evens">
                                                        Evens
                                                    </ToggleButton>
                                                    <ToggleButton value="odds" aria-label="Odds">
                                                        Odds
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                            {/*<div className="ml-10 flex items-center mr-2">*/}
                                            {/*    <VisibilityIcon />*/}
                                            {/*</div>*/}
                                            {/*<div className="flex items-center">*/}
                                            {/*    <Switch checked={showCallbacks} onChange={event => setShowCallbacks(event.target.checked)} name="showCallbacks" />*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                        </div>

                        <div className="mt-2">
                            {sortedCallbacks.map((callback) => (
                                <CallbackItem
                                    key={`callback-${callback.id}`}
                                    callback={callback}
                                    setEditId={setEditId}
                                    onClick={() => centerMap(callback.address.lat, callback.address.lng)}/>
                            ))}
                            {!sortedCallbacks.length &&
                                <div className="text-center mt-4">
                                    No callbacks.
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default Callbacks;