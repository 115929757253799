import React, {useState} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import Check from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import {Formik, Form} from "formik";
import {useParams, useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {SCHEMA as supportRecordSchema} from '@devsontap/tce-api/core/models/supportRecord';
import {PaymentMethods} from "@devsontap/tce-api/core/models/enums";

import {saveSupportRecord} from "../../redux/supportRecords";
import ContributionErrorDialog from "../supportRecords/details/dialogs/contributionError";
import MinimumAmountDialog from "./minimumAmountDialog";
import Contribution from "../supportRecords/details/contribution";
import StripeDialog from "../supportRecords/details/dialogs/stripe";
import SupporterInfoDialog from "./supporterInfoDialog";
import Logo from "../_common/logo";
import {useLoginSelector} from "../../redux/login/selectors";
import {campaignSelector} from "../../redux/campaigns/selectors";

import infoIcon from "../../img/icn-info.svg";

const MINIMUM = 300;

const RecurringContribution = () => {
    const [showContributionError, setShowContributionError] = useState(false);
    const [showStripeDialog, setShowStripeDialog] = useState(false);
    const [showSupporterInfoDialog, setShowSupporterInfoDialog] = useState(false);
    const [showMinimumAmountDialog, setShowMinimumAmountDialog] = useState(false);
    const {campaignId} = useParams();
    const {campaignName} = useSelector(campaignSelector(campaignId));
    const {canvasser} = useLoginSelector();
    const history = useHistory();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const validateContribution = (values, validateForm, handleSubmit) => {
        validateForm().then(errors => {
            if (errors.contribution) {
                setShowContributionError(true)
            } else if (values.contribution.amount >= MINIMUM) {
                setShowStripeDialog(true);
            } else {
                setShowMinimumAmountDialog(true);
            }
        });
    };

    const goBack = () => history.push(document.location.pathname.replace("/contribution", ""));
    const onLogoClick = () => goBack();

    return (
        <div className="flex column h100">
            <AppBar position="static" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        <div className="flex flex1 text-align-left align-items-center">
                            <Logo onClick={onLogoClick} />
                        </div>
                        <div className="marginRight15">
                            <IconButton onClick={() => setShowSupporterInfoDialog(true)}>
                                <img src={infoIcon} alt="info" />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Formik
                initialValues={_getDefaultSupportRecord(canvasser, campaignId, campaignName)}
                onSubmit={(values, {resetForm}) => {
                    console.log("onsubmit", values);
                    const onSave = () => {
                        resetForm();
                        enqueueSnackbar("Contribution Successful", {variant: "success"});
                    };

                    dispatch(saveSupportRecord(values, onSave, false, null))
                }}
                validationSchema={supportRecordSchema}>
                {({values, handleChange, setFieldValue, handleSubmit, touched, errors, validateForm}) => {
                    return (
                        <Form className="flex1 home-main-content scrollY">
                            <Contribution {...{values, handleChange, touched, errors, setFieldValue}} showQuickAmounts={true} hidePaymentMethods={true} />
                            <div className="support-record-details-next-button-container">
                                <Fab color="secondary" aria-label="Next" onClick={() => validateContribution(values, validateForm, handleSubmit)}>
                                    <Check htmlColor="#ffffff" />
                                </Fab>
                            </div>
                            <ContributionErrorDialog {...{showContributionError, setShowContributionError}} />
                            <StripeDialog {...{showStripeDialog, setShowStripeDialog, handleChange, values, setFieldValue}} goToNextSlide={handleSubmit} />
                            <SupporterInfoDialog {...{showSupporterInfoDialog, setShowSupporterInfoDialog, values, setFieldValue, handleChange, errors, touched}} />
                            <MinimumAmountDialog {...{showMinimumAmountDialog, setShowMinimumAmountDialog, MINIMUM}} />
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
};

function _getDefaultSupportRecord(canvasser, campaignId, campaignName) {
    return {
        createdAt: new Date(),
        canvasser,
        address: {
            line1: 'PHONE_BANK',
            line2: '',
            city: 'PHONE_BANK',
            stateCode: 'TX',
            zip: '78702'
        },
        campaignId,
        campaignName,
        contribution: {
            amount: '',
            paymentMethod: PaymentMethods[0].value,
            recurring: true
        },
        email: '',
        phone: '',
        firstName: 'PHONE_BANK',
        lastName: 'PHONE_BANK',
        houseNumber: '11111',
        unitNumber: '',
        signatureUrl: 'PHONE_BANK',
        streetName: 'PHONE_BANK',
        surveyAnswers: [],
        receiveTexts: false,
        supporterContactId: '',
    }
}

export default RecurringContribution;