import {
    SUPPORTERS_SUCCESS,
    TURF_SUCCESS
} from "./types";
import { loadCallbacks } from "../callbacks";

export const loadCanvasserTurf = (canvasserId, currentTurfId) => (
    (dispatch, getState, { api }) => {
        return api.getCanvasserTurfId(canvasserId)
            .then(async turfId => {
                if (turfId && turfId !== currentTurfId) {
                    const turf = await api.getTurf(turfId);
                    dispatch({ type: TURF_SUCCESS, payload: turf });
                    if (turf.downloadSupporters) {
                        dispatch(loadSupporters(turf.center.lat, turf.center.lng, turf.radiusInMeters));
                    }
                    if (turf.downloadCallbacks) {
                        return dispatch(loadCallbacks(turf.center.lat, turf.center.lng, turf.radiusInMeters));
                    }
                }
            })
            .catch(err => {
                console.error("problem loading turf", err);
            });
    }
);

export const loadSupporters = (lat, lng, radiusInMeters) => (
    (dispatch, getState, { api }) => {
        return api.searchSupporters(lat, lng, radiusInMeters)
            .then(supporters => {
                supporters.forEach(supporter => {
                    supporter.lastContributionDate = supporter.lastContributionDate?.toMillis();
                    supporter.firstContributionDate = supporter.firstContributionDate?.toMillis();
                    supporter.lastLetterDate = supporter.lastLetterDate?.toMillis();
                });
                return dispatch({ type: SUPPORTERS_SUCCESS, payload: supporters });
            })
            .catch(err => {
                console.error("problem loading canvassers", err);
            });
    }
);

export const flagSupporter = (supporterId, flaggedReason, flaggedBy, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.flagSupporter(supporterId, flaggedReason, flaggedBy)
            .then(() => enqueueSnackbar('Supporter Flagged', { variant: 'success' }))
            .catch(err => {
                console.error(err);
                enqueueSnackbar('Problem Flagging Supporter', { variant: 'error' });
            });
    }
);