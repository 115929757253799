/*global google*/
import {useState, useEffect} from 'react';
import { url } from '../utils/markerIcons';

function useCallbackMarkerManager(map, callbacks, lastUpdated, onMarkerClick, onMarkerDragged) {
    const [markerMap, setMarkerMap] = useState({});

    useEffect(() => {
        if (map && callbacks && callbacks.length) {
            const oldIds = Object.keys(markerMap);
            const newCallbacks = callbacks.filter(callback => !oldIds.includes(callback.id));
            const ids = callbacks.map(callback => callback.id);
            const removedIds = oldIds.filter(id => !ids.includes(id));

            const newMap = newCallbacks.reduce((ret, callback) => {
                const marker = new google.maps.Marker({
                    position: {lat: callback.address.lat, lng: callback.address.lng},
                    map,
                    icon: url("house"),
                    draggable: true
                });
                marker.addListener('click', () => onMarkerClick(marker, callback.id));
                google.maps.event.addListener(marker, 'dragend', event => {
                    onMarkerDragged(callback.id, event.latLng.lat(), event.latLng.lng());
                });
                ret[callback.id] = marker;
                return ret;
            }, {...markerMap});

            removedIds.forEach(id => {
                delete newMap[id];
                markerMap[id].setMap(null);
            });

            setMarkerMap(newMap);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, callbacks, lastUpdated]);

    return markerMap;
}

export default useCallbackMarkerManager;