import React from 'react';

import TCEDialog from "../../_common/dialog";
import {formatMoneyFromPennies} from "../../../utils/money";

const MinimumAmountDialog = ({ showMinimumAmountDialog, setShowMinimumAmountDialog, MINIMUM }) => {
    return (
        <TCEDialog
            open={showMinimumAmountDialog}
            onClose={() => setShowMinimumAmountDialog(false)}
            title={`${formatMoneyFromPennies(MINIMUM)} Minimum`}
            maxWidth="md"
            actions={[
                {label: 'OK', onClick: () => setShowMinimumAmountDialog(false)},
            ]}>
            {`There is a ${formatMoneyFromPennies(MINIMUM)} minimum for recurring contributions.`}
        </TCEDialog>
    );
};

export default MinimumAmountDialog;