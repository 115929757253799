import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import {PaymentMethods} from '@devsontap/tce-api/core/models/enums';

import ccLogos from '../../../../img/icn_cc_logo@2x.png';

import './index.css';
import TenderEntryTextField from "../../../_common/textfields/tenderEntry";
import {formatMoneyFromPennies} from "../../../../utils/money";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ContributionLevelsDialog from "../dialogs/contributionLevels";
import useCurrentOrg from "../../../../hooks/useCurrentOrg";

const AMOUNTS = [365,181,120,72,52,36];

const Contribution = ({ values, handleChange, setFieldValue, showQuickAmounts, hidePaymentMethods }) => {
    const [showContributionLevels, setShowContributionLevels] = useState(false);
    const amountWidth = 22 * Math.max(formatMoneyFromPennies(values.contribution.amount).length - 1, 1);
    const org = useCurrentOrg();

    return (
        <div className="contribution-container flex flex1 column">
            <Paper className="contribution-card" square>
                <div className="contribution-title nothing">
                    Will you meet or beat our goal?
                </div>
                <div className="flex center marginTop10">
                    {showQuickAmounts ? AMOUNTS.map((amount, index) => {
                        return (
                            <Button
                                variant="text"
                                color="secondary"
                                className="marginRight10"
                                key={`quick-amount-${amount}`}
                                onClick={() => setFieldValue('contribution.amount', amount * 100)}>
                                {`$${amount}`}
                            </Button>
                        )
                    }) : null}
                </div>
                <div className="contribution-amount-container marginTop15 flex center">
                    <div className="flex">
                        <div className="montserat contribution-amount-dollar">$</div>
                        <div className="flex">
                            <TenderEntryTextField
                                name="contribution.amount"
                                inputProps={{className: "contribution-amount", style: {width: `${amountWidth}px`}}}
                                InputProps={{disableUnderline: true, fullWidth: false}}
                                onChange={handleChange}
                                value={values.contribution.amount}
                                placeholder="0.00" />
                        </div>
                    </div>
                </div>
                <div className="flex center marginTop20">
                    <img src={ccLogos} className="contribution-cc-logos" alt="cc logos" />
                </div>
            </Paper>
            {!hidePaymentMethods &&
                <div className="contribution-payment-method-container">
                    <div className="contribution-subtitle montserat marginBottom15">
                        Select a Payment Method
                    </div>
                    <div className="flex center marginBottom10">
                        <ToggleButtonGroup
                            value={values.contribution.paymentMethod}
                            name="contribution.paymentMethod"
                            exclusive
                            size="medium"
                            onChange={(event, paymentMethod) => setFieldValue('contribution.paymentMethod', paymentMethod || values.contribution.paymentMethod, false)}
                            aria-label="Payment Method">
                            {PaymentMethods.map((option, index) => (
                                <ToggleButton value={option.value} aria-label={option.label} key={`payment-method-${index}`}>
                                    {option.label}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                    <div className="flex center">
                        <Checkbox
                            checked={values.contribution.recurring}
                            onChange={event => setFieldValue('contribution.recurring', event.target.checked, false)}
                            color="secondary"
                            aria-label="Recurring"
                            disabled={values.contribution.paymentMethod !== 'credit'}/>
                        <div className="montserat contribution-payment-method-label">
                            I'd like to make this a recurring contribution
                        </div>
                    </div>
                    <div className="contribution-see-levels-button-container flex center">
                        <Button variant="text" onClick={() => setShowContributionLevels(true)} color="secondary">
                            SEE FINANCIALS AND CONTRIBUTION LEVELS
                        </Button>
                    </div>
                </div>
            }
            <ContributionLevelsDialog {...{showContributionLevels, setShowContributionLevels, financials_info: org?.financialsInfo}} />
        </div>
    )
};

Contribution.defaultProps = {
    hidePaymentMethods: false
};

export default Contribution;