import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { format } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import TrashIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddSupportRecordIcon from "@material-ui/icons/PersonAdd";

import TCEDialog from "../../../_common/dialog";
import { deleteCallback } from "../../../../redux/callbacks";

const CallbackItem = ({ callback, setEditId, onClick }) => {
    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    return (
        <div className="py-2 border-solid border-0 border-b border-black flex" onClick={onClick}>
            <div className="flex-1">
                <div className="text-xs text-[gray]">
                    {format(new Date(callback.createdAt), "MM/dd/yyyy @ h:mm a")}
                </div>
                <div className="font-bold">
                    {callback.houseNumber} {callback.streetName} {callback.address?.line2 || ""}
                </div>
                <div className="text-sm">
                    {callback.notes}
                </div>
            </div>
            <div>
                <IconButton onClick={() => setShowDialog(true)}>
                    <TrashIcon />
                </IconButton>
                <IconButton onClick={() => setEditId(callback.id)}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => history.push(`${document.location.pathname}/callbacks/${callback.id}/add`)}>
                    <AddSupportRecordIcon />
                </IconButton>
            </div>
            <TCEDialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                title="Confirm Delete"
                actions={[
                    { label: "cancel", onClick: () => setShowDialog(false) },
                    { label: "confirm", onClick: () => {
                        setShowDialog(false);
                        dispatch(deleteCallback(callback.id, enqueueSnackbar));
                    }}
                    ]}>
                Are you sure you want to delete the callback for {callback.address?.line1}?
            </TCEDialog>
        </div>
    )
};

export default CallbackItem;