import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { SCHEMA } from "@devsontap/tce-api/core/models/callback";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import TrashIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import AddSupportRecordIcon from "@material-ui/icons/PersonAdd";
import cx from 'classnames';

import { callbackSelector } from "../../../../redux/callbacks/selectors";
import { useLoginSelector } from "../../../../redux/login/selectors";
import { deleteCallback, saveCallback } from "../../../../redux/callbacks";
import CallbackDetailsForm from "./form";
import TCEDialog from "../../../_common/dialog";

const CallbackDetails = ({ callbackId, onClose }) => {
    const callback = useSelector(callbackSelector(callbackId));
    const { canvasser } = useLoginSelector();
    const [saving, setSaving] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const saveRef = useRef(null);

    return (
        <div>
            <Formik
                initialValues={{
                    houseNumber: '',
                    streetName: '',
                    address: {
                        line1: '',
                        line2: '',
                        city: '',
                        stateCode: 'TX',
                        zip: ''
                    },
                    notes: '',
                    canvasser,
                    ...(callback || {})
                }}
                validationSchema={SCHEMA}
                onSubmit={values => {
                    // TODO: Dispatch saveCallback
                    setSaving(true);
                    dispatch(saveCallback(values, setSaving, onClose, enqueueSnackbar))
                }}>
                {({ values, setFieldValue }) => (
                    <div>
                        <div className="flex mb-4">
                            <div>
                                <IconButton onClick={onClose}>
                                    <BackIcon />
                                </IconButton>
                            </div>
                            <div className="flex items-center justify-center text-xl font-bold flex-1">
                                Add / Edit
                            </div>
                            <div>
                                <IconButton onClick={() => saveRef.current.click()}>
                                    <SaveIcon />
                                </IconButton>
                            </div>
                            <div className={cx({"invisible": !callback})}>
                                <IconButton onClick={() => setShowDialog(true)}>
                                    <TrashIcon />
                                </IconButton>
                                <IconButton onClick={() => history.push(`${document.location.pathname}/callbacks/${callback.id}/add`)}>
                                    <AddSupportRecordIcon />
                                </IconButton>
                            </div>
                        </div>
                        <CallbackDetailsForm values={values} setFieldValue={setFieldValue} saving={saving} saveRef={saveRef} />
                    </div>
                )}
            </Formik>
            <TCEDialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                title="Confirm Delete"
                actions={[
                    { label: "cancel", onClick: () => setShowDialog(false) },
                    { label: "confirm", onClick: () => {
                            setShowDialog(false);
                            dispatch(deleteCallback(callback.id, enqueueSnackbar));
                            onClose();
                        }}
                ]}>
                Are you sure you want to delete the callback for {callback?.address?.line1}?
            </TCEDialog>
        </div>
    )
};

export default CallbackDetails;