import React, {useRef, useState, useEffect} from 'react';

import './index.css';

const Paint = ({penColor, penSize, eraseSize, erase, onEnd, redoRef, undoRef, clearRef, saveRef, canvasImg, onSave, imgRef}) => {
    const [points, setPoints] = useState([]);
    const [strokes, setStrokes] = useState([]);
    const [lastStroke, setLastStroke] = useState(null);
    const [paint, setPaint] = useState(false);
    const canvasRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            console.log("resizing canvas");
            canvasRef.current.width = canvasRef.current.offsetWidth;
            canvasRef.current.height = canvasRef.current.offsetHeight;
        } else {
            console.log("womp womp");
        }
    }, []);

    useEffect(() => {
        if (imgRef && imgRef.current) {
            console.log("drawing canvas img");
            drawImage();
        } else {
            console.log("womp womp");
        }
    }, [canvasImg]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (points.length > 0) {
            drawNewStroke();
        }
    }, [points.length]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // if (strokes.length < previousStrokeCount) {
        //
        // }
        canvasRef.current.getContext('2d').clearRect(0, 0, canvasRef.current.offsetWidth, canvasRef.current.offsetHeight);
        drawImage();
        redraw();
    }, [strokes.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const addPoint = (x, y, drag) => {
        setPoints([...points, {
            x: x,
            y: y,
            drag: drag,
            color: penColor || "#000000",
            penSize: (erase ? eraseSize : penSize) || 2,
            erase: erase || false
        }]);
    };

    const redraw = () => {
        const ctx = canvasRef.current.getContext('2d');

        paintPoints(ctx, points);

        strokes.forEach(points => {
            paintPoints(ctx, points);
        });
    };

    const drawNewStroke = () => {
        const ctx = canvasRef.current.getContext('2d');
        paintPoints(ctx, points);
    };

    const paintPoints = (ctx, points) => {
        // console.log("paintPoints", points);
        if (points.length && points[0].erase) {
            ctx.globalCompositeOperation = "destination-out";
        } else {
            ctx.globalCompositeOperation = "source-over";
        }

        points.forEach((point, index) => {
            // console.log("painting ", point);
            ctx.beginPath();

            if (point.drag) {
                ctx.moveTo(points[index-1].x, points[index-1].y);
            } else {
                ctx.moveTo(point.x, point.y);
            }

            ctx.lineTo(point.x, point.y);
            ctx.strokeStyle = point.color;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineWidth = point.penSize;
            ctx.stroke();
        });

        ctx.globalCompositeOperation = "source-over";
    };

    const undo = () => {
        const newStrokes = [...strokes];
        setLastStroke(newStrokes.pop());
        setStrokes(newStrokes);
    };

    const redo = () => {
        if (lastStroke) {
            setStrokes([...strokes, lastStroke]);
            setLastStroke(null);
        }
    };

    const clear = () => {
        canvasRef.current.getContext('2d').clearRect(0, 0, canvasRef.current.offsetWidth, canvasRef.current.offsetHeight);
        setPoints([]);
        setStrokes([]);
    };

    const drawImage = () => {
        const ctx = canvasRef.current.getContext('2d');
        console.log("drawImage", canvasRef.current, ctx);
        ctx.drawImage(imgRef.current, 0, 0);
    };

    const onTouchStart = (event) => {
        console.log("onTouchStart");
        const viewportOffset = canvasRef.current.getBoundingClientRect();
        const mouseX = (event.changedTouches ? event.changedTouches[0].pageX : event.pageX) - viewportOffset.left,
            mouseY = (event.changedTouches ? event.changedTouches[0].pageY : event.pageY) - viewportOffset.top;

        addPoint(mouseX, mouseY, false);
        setPaint(true);
        // drawNewStroke();
    };

    const onTouchMove = (event) => {
        const viewportOffset = canvasRef.current.getBoundingClientRect();
        const mouseX = (event.changedTouches ? event.changedTouches[0].pageX : event.pageX) - viewportOffset.left,
            mouseY = (event.changedTouches ? event.changedTouches[0].pageY : event.pageY) - viewportOffset.top;

        if (paint) {
            addPoint(mouseX, mouseY, true);
            // drawNewStroke();
        }
    };

    const onTouchEnd = (event) => {
        setStrokes([...strokes, points]);
        setLastStroke(points);
        setPoints([]);

        setPaint(false);
        // console.log(this.points);

        if (onEnd) {
            onEnd();
        }
    };

    const toDataURL = () => {
        return canvasRef.current.toDataURL('image/png', 0.5);
    };

    return (
        <>
            <canvas
                className="paint-canvas h100 w100"
                ref={canvasRef}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                onMouseDown={onTouchStart}
                onMouseUp={onTouchEnd}
                onTouchMove={onTouchMove}
                onMouseMove={onTouchMove} />
            <div style={{display: "none"}}>
                <input type="button" onClick={redo} ref={redoRef} />
                <input type="button" onClick={undo} ref={undoRef} />
                <input type="button" onClick={clear} ref={clearRef} />
                <input type="button" onClick={() => onSave(toDataURL())} ref={saveRef} />
            </div>
        </>
    )
};

export default Paint;