import React from 'react';
import Paper from '@material-ui/core/Paper';

import useCurrentOrg from "../../../../hooks/useCurrentOrg";

import smile from '../../../../img/img-smile@2x.png';

import './index.css';

const ThankYou = ({ hideContributionScreen }) => {
    const org = useCurrentOrg();

    return (
        <div className="thank-you-container flex flex1">
            <Paper className="flex1 flex center thank-you-card">
                <div>
                    <div className="thank-you-title flex nothing center">
                        <div>
                            Thank You Kindly!
                        </div>
                        <div className="flex center">
                            <img src={smile} alt="smiley face" className="thank-you-smile" />
                        </div>
                    </div>
                    <div className="nothing thank-you-should-know">
                        You should know...
                    </div>
                    <div className="thank-you-text montserat">
                        { !hideContributionScreen ?
                            <>
                                {org.strings.thankYou.map((thankYouString, index) => (
                                    <div className="marginTop20" key={`thank-you-${index}`}>
                                        {thankYouString}
                                    </div>
                                ))}
                            </>
                            :
                            <div className="marginTop20">
                                Our mission is to empower Texans to fight pollution through sustained grassroots organizing campaigns that shift
                                corporate and governmental policy. Thank you for joining our efforts today to protect our air, water, and land.
                                Together we are working toward a Texas free from pollution!
                            </div>
                        }
                    </div>
                </div>
            </Paper>
        </div>
    )
};

export default ThankYou;