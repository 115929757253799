import {getLocal, saveLocal} from "@devsontap/dot-react-common/core/utils/localStorage";

import {
    STAT_INCREMENT,
    STAT_DECREMENT,
} from "./types";
import {USER_LOGOUT} from "../login/types";

const KEY_STATS = "stats";
const cachedStats = getLocal(KEY_STATS, true);
const INITIAL_STATE = {
    contacts: 0,
    clips: 0,
    doors: 0
};

const reducer = (state = cachedStats || INITIAL_STATE, action) => {
    let stats = null;
    switch (action.type) {
        case STAT_INCREMENT:
            stats = { ...state };
            stats[action.payload] = stats[action.payload] + 1;
            saveLocal(KEY_STATS, stats, true);
            return stats;
        case STAT_DECREMENT:
            stats = { ...state };
            stats[action.payload] = Math.max(stats[action.payload] - 1, 0);
            saveLocal(KEY_STATS, stats, true);
            return stats;
        case USER_LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default reducer;


