import {useShallowEqualSelector} from "../index";

/**
 * Returns the login data
 */
export const useLoginSelector = () => {
    return useShallowEqualSelector(({ login }) => {
        return login
    });
};
