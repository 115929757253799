const key = "AIzaSyCELP40X2MXGwpkpKv577DqiS2zMAgOWAQ";

export const geocodeUsersLocation = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(position => {
            const { coords: { latitude, longitude } } = position;
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${key}`)
                .then(async response => {
                    const json = await response.json();
                    console.log("json", json);
                    resolve(json.results[0].address_components.reduce((ret, component) => {
                        if (component.types.includes("locality")) {
                            ret.city = component.long_name;
                        } else if (component.types.includes("postal_code")) {
                            ret.zip = component.long_name;
                        } else if (component.types.includes("route")) {
                            ret.streetName = component.short_name;
                        } else if (component.types.includes("administrative_area_level_1")) {
                            ret.stateCode = component.short_name;
                        }
                        return ret;
                    }, { lat: latitude, lng: longitude }));
                })
                .catch(err => {
                    console.error("failed to geocode position", err);
                    reject(err);
                });
        }, error => {
            console.error("failed to fetch location", error);
            reject(error);
        }, {
            enableHighAccuracy: true
        })
    });
};