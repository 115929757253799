import { createSlice } from '@reduxjs/toolkit';
import {getLocal, saveLocal} from "@devsontap/dot-react-common/core/utils/localStorage";

const KEY_CALLBACKS = "callbacks";

const INITIAL_STATE = {
    loading: false,
    callbackMap: getLocal(KEY_CALLBACKS, true) || {}
};

const callbacksSlice = createSlice({
    name: 'callbacks',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        callbacksSuccess(state, action) {
            state.loading = false;
            state.callbackMap = action.payload.reduce((ret, callback) => ({...ret, [callback.id]: callback }), state.callbackMap);
            saveLocal(KEY_CALLBACKS, state.callbackMap, true);
        },
        callbackSuccess(state, action) {
            state.callbackMap[action.payload.id] = action.payload;
            saveLocal(KEY_CALLBACKS, state.callbackMap, true);
        },
        deleteCallback(state, action) {
            delete state.callbackMap[action.payload];
            saveLocal(KEY_CALLBACKS, state.callbackMap, true);
        },
        logout(state) {
            state.callbackMap = {};
        }
    }
});

export const { loading, callbacksSuccess, callbackSuccess, logout } = callbacksSlice.actions;

export default callbacksSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadCallbacks = (lat, lng, radiusInMeters, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.callbacks.search(lat, lng, radiusInMeters)
            .then(callbacks => {
                console.log("Callbacks", callbacks);
                return dispatch(callbacksSuccess(callbacks));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);

export const saveCallback = (callback, setSaving, onComplete, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch(loading(true));

        try {
            const coords = await api.geocodeAddress(`${callback.address.line1} ${callback.address.city}, ${callback.address.stateCode} ${callback.address.zip}`);
            if (coords.lat && coords.lng) {
                callback.address.lat = coords.lat;
                callback.address.lng = coords.lng;
            }
        } catch (e) {
            // Ignore this error
        }

        if (!callback.address.lat) {
            return enqueueSnackbar("Could not find lat/lng, please try again.", {variant: "error"});
        }

        // createdAt might have been converted to string when persisted to disk, so make sure we're sending a Date back
        if (typeof callback.createdAt == "string") {
            callback.createdAt = new Date(callback.createdAt);
        }

        return api.callbacks.save(callback, callback.address.lat, callback.address.lng)
            .then(result => {
                setSaving(false);
                dispatch(loading(false));
                dispatch(callbackSuccess(result));
                onComplete();
            })
            .catch(err => {
                console.error(err);
                setSaving(false);
                dispatch(loading(false));
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
);

export const deleteCallback = (id, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.callbacks.delete(id)
            .then(() => {
                console.log("Deleted", id);
                return dispatch(callbacksSlice.actions.deleteCallback(id));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);