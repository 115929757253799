import React, {useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {useFormik} from "formik";
import * as Yup from 'yup';

import TCEDialog from "../../_common/dialog";

import './index.css'

const VerifyPinForm = ({ hideVerifyPin, confirmationResult }) => {
    const [loading, setLoading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const {values, errors, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            verificationCode: ''
        },
        validationSchema: Yup.object().shape({
            verificationCode: Yup.string().length(6, "Please enter a 6-digit code").required("Please enter a 6-digit code")
        }),
        onSubmit: values => {
            setLoading(true);
            confirmationResult.confirm(`${values.verificationCode}`)
                .catch(err => {
                    setLoading(false);
                    setShowErrorDialog(true);
                });
        }
    });

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-align-left login-title roboto">
                Verify Code
            </div>
            <div className="login-helper-text roboto alignLeft">
                Enter the code that was sent to you via SMS.
            </div>
            <div className="login-row">
                <div>
                    <TextField
                        name="verificationCode"
                        type="tel"
                        placeholder="120832"
                        label="Verification Code"
                        value={values.verificationCode}
                        onChange={handleChange}
                        error={errors.verificationCode && touched.verificationCode}
                        helperText={errors.verificationCode && touched.verificationCode ? errors.verificationCode : null}
                        fullWidth />
                </div>
            </div>
            <div className="login-buttons">
                {loading ?
                    <div className="flex justify-end">
                        <CircularProgress size={40} />
                    </div>
                    :
                    <div className="flex">
                        <div className="flex1 flex justify-end">
                            <Button variant="text" color="primary" onClick={hideVerifyPin}>Back</Button>
                        </div>
                        <div className="marginLeft15">
                            <Button variant="contained" color="secondary" type="submit">Verify</Button>
                        </div>
                    </div>
                }
            </div>
            <TCEDialog
                open={showErrorDialog}
                onClose={() => setShowErrorDialog(false)}
                title="Problem Validating Code"
                actions={[{ label: 'OK', onClick: () => setShowErrorDialog(false) }]}>
                The code entered could not be validated. Please try again.
            </TCEDialog>
        </form>
    )
};

export default VerifyPinForm;