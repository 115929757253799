import React from 'react';

import useCurrentOrg from "../../../hooks/useCurrentOrg";

import logo from '../../../img/tce-horiz-logo-color-white-text-white-outline@2x.png';

const Logo = ({ className, onClick }) => {
    const org = useCurrentOrg();

    return (
        <img src={org?.logoUrl || logo} className={className || "home-toolbar-logo cursor-pointer"} alt="TCE logo" onClick={onClick} />
    )
};

export default Logo;