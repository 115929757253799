import React, {useMemo} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import {useHistory} from "react-router";
import { useSelector } from "react-redux";
import { hasCollectedLetters } from "@devsontap/tce-api/core/utils";

import Logo from "../_common/logo";
import {formatMoneyFromPennies} from "../../utils/money";
import {useSupportRecordsSelector} from "../../redux/supportRecords/selectors";
import {useStatsSelector} from "../../redux/stats/selectors";
import {callbacksSelector} from "../../redux/callbacks/selectors";

import './index.css';

const Stats = () => {
    const history = useHistory();
    const { records } = useSupportRecordsSelector();
    const { contacts, clips } = useStatsSelector();
    const callbacks = useSelector(callbacksSelector);

    const numRecords = records ? records.length : 0;

    const {data, totals, total} = useMemo(() => {
        let tribs = 0;
        let members = 0;
        let emails = 0;
        let phones = 0;
        let texts = 0;
        let letters = 0;
        let credit_tce = 0;
        let credit_sub_tce = 0;
        let check_tce = 0;
        let cash_tce = 0;
        let credit_tce_fund = 0;
        let credit_sub_tce_fund = 0;
        let check_tce_fund = 0;
        let cash_tce_fund = 0;
        let total = 0;

        if (records) {
            records.forEach(record => {
                let contributionAmount = record.contribution.amount;
                if (contributionAmount > 0) {
                    tribs++;
                    if (record.contribution.token) {
                        // KBM - Let's get this biz logic somewhere more sane.
                        contributionAmount *= record.contribution.interval_count === 3 ? 2 : 6;
                    }
                    total += contributionAmount;

                    if (contributionAmount >= 1500 || record.contribution.token) {
                        members++;
                    }

                    if (_didContributeToNonProfit(record.surveyAnswers)) {
                        if (_isCreditPayment(record.contribution)) {
                            if (record.contribution.token) {
                                credit_sub_tce_fund += contributionAmount;
                            } else {
                                credit_tce_fund += contributionAmount;
                            }
                        } else if (_isCheckPayment(record.contribution)) {
                            check_tce_fund += contributionAmount;
                        } else {
                            cash_tce_fund += contributionAmount;
                        }
                    } else {
                        if (_isCreditPayment(record.contribution)) {
                            if (record.contribution.token) {
                                credit_sub_tce += contributionAmount;
                            } else {
                                credit_tce += contributionAmount;
                            }
                        } else if (_isCheckPayment(record.contribution)) {
                            check_tce += contributionAmount;
                        } else {
                            cash_tce += contributionAmount;
                        }
                    }
                }

                if (record.email) {
                    emails++;
                }
                if (record.phone) {
                    phones++;
                }
                if (record.receiveTexts) {
                    texts++;
                }
                if (hasCollectedLetters(record.surveyAnswers, record)) {
                    letters += record.numLettersWritten;
                }
            })
        }

        let data = [
            {name: 'Doors', amt: contacts + (callbacks?.length || 0)},
            {name: 'Contacts', amt: contacts},
            {name: 'Clips', amt: clips},
            {name: 'Sigs', amt: records ? records.length : 0},
            {name: 'Tribs', amt: tribs},
            {name: 'Members', amt: members},
            {name: 'Emails', amt: emails},
            {name: 'Phones', amt: phones},
            {name: 'Texts', amt: texts},
            {name: 'Letters', amt: letters}
        ];

        let totals = [
            {name: 'TCE', credit: _toFixed(credit_tce), recurring: _toFixed(credit_sub_tce), check: _toFixed(check_tce), cash: _toFixed(cash_tce)},
            {name: 'TCE Fund', credit: _toFixed(credit_tce_fund), recurring: _toFixed(credit_sub_tce_fund), check: _toFixed(check_tce_fund), cash: _toFixed(cash_tce_fund)}
        ];
        return { data, totals, total };
    }, [numRecords]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex column h100">
            <AppBar position="static" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        <div className="flex flex1 text-align-left align-items-center" onClick={() => history.push(history.location.pathname.replace("/stats", ""))}>
                            <Logo />
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="stats-container flex1 montserat scrollY">
                <div className="text-align-left page-header montserat">
                    Stats
                </div>
                <div className="marginTop15">
                    <BarChart width={900} height={300} data={data}
                              margin={{top: 5, right: 0, left: 20, bottom: 5}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name" />
                        <YAxis/>
                        <Tooltip/>
                        <Bar dataKey="amt" fill="#00b4e8" label />
                    </BarChart>
                </div>
                <div className="text-align-left page-header montserat marginTop30">
                    Total - {formatMoneyFromPennies(total)}
                </div>
                <div className="marginTop15">
                    <BarChart width={900} height={300} data={totals}
                              margin={{top: 5, right: 0, left: 20, bottom: 5}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip/>
                        <Legend />
                        <Bar dataKey="credit" fill="#00b4e8" label />
                        <Bar dataKey="recurring" fill="#ec8700" label />
                        <Bar dataKey="check" fill="#4db04a" label />
                        <Bar dataKey="cash" fill="#ffd400" label />
                    </BarChart>
                </div>
            </div>
        </div>
    )
};

function _toFixed(amount) {
    return amount / 100.0;
}

function _isCreditPayment(contribution) {
    return contribution.paymentMethod.toLowerCase() === "credit";
}

function _isCheckPayment(contribution) {
    return contribution.paymentMethod.toLowerCase() === "check";
}

function _didContributeToNonProfit(surveyAnswers) {
    return surveyAnswers && surveyAnswers.length &&
        surveyAnswers[0].question.toLowerCase() === "Is this a tax-deductible donation to TCE Fund, our 501(c)3 organization?".toLowerCase() &&
        surveyAnswers[0].answer.toLowerCase() === "Yes".toLowerCase();
}

export default Stats;