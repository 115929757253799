import React, { useEffect, useState, useMemo } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Fab from "@material-ui/core/Fab";
import SortIcon from '@material-ui/icons/Sort';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/PersonAdd';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from "react-router";
import {compareDesc} from 'date-fns';

import Loading from "../_common/loading";
import SupportRecordItem from "./item";
import EditSupportRecord from "./edit";
import SupportRecordDetails from "./details";
import Stats from "../stats";
import Scoty from "../scoty";
import FullscreenDialog from "../_common/dialog/fullScreen";
import RecurringContribution from "../recurringContribution";
import useComponentForAction from "../../hooks/useComponentForAction";
import {campaignSelector} from "../../redux/campaigns/selectors";
import {useSupportRecordsSelector} from "../../redux/supportRecords/selectors";
import {inflateSupportRecords} from "../../redux/supportRecords";
import {loadCampaign} from "../../redux/campaigns";

import './index.css';

const SORT_OPTIONS = ["Created", "Street Name", "Address"];
const VISIBILITY_OPTIONS = ["All", "Evens", "Odds"];

const SupportRecords = () => {
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
    const [visibility, setVisibility] = useState(VISIBILITY_OPTIONS[0]);
    const {campaignId, recordId, action, todoId} = useParams();
    const history = useHistory();
    const campaign = useSelector(campaignSelector(campaignId));
    const { ids, records, lastUpdated } = useSupportRecordsSelector();
    const dispatch = useDispatch();
    const actionElement = useComponentForAction(action);

    if (!Boolean(campaign)) {
        console.log("no campaign? LATERZ");
        history.push('/campaigns');
    }

    const sortedRecords = useMemo(() => {
        console.log("sorted", sortBy);
        return records.sort((r1, r2) => {
            if (sortBy === SORT_OPTIONS[0]) {
                return compareDesc(r1.createdAt, r2.createdAt);
            } else if (sortBy === SORT_OPTIONS[1]) {
                console.log("sorting by street name");
                return r1.streetName.localeCompare(r2.streetName);
            }
            return r1.address.line1.localeCompare(r2.address.line1);
        })
    }, [records.length, sortBy, lastUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredRecords = useMemo(() => {
        return sortedRecords.filter(record => {
            if (visibility === VISIBILITY_OPTIONS[0]) {
                return true;
            } else if (visibility === VISIBILITY_OPTIONS[1]) {
                return record.houseNumber % 2 === 0;
            }
            return record.houseNumber % 2 === 1;
        })
    }, [records.length, sortBy, visibility, lastUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // TODO: If supportRecords is  null we need to inflate the cached ids from the db. We are relying on Firestore having the cached data we need, even if we're offline
        if (ids.length !== records.length) {
            console.log("ids and map keys dont match!", ids.length, records.length);
            setLoading(true);
            dispatch(inflateSupportRecords(ids, setLoading));
        } else {
            console.log("ids", ids);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(loadCampaign(campaignId));
    }, [dispatch, campaignId]);

    return (
        <div className="support-records-container">
            <div className="support-records-add-fab">
                <Fab color="secondary" aria-label="Add" onClick={() => history.push(`/campaigns/${campaignId}/add`)}>
                    <AddIcon htmlColor="#ffffff" />
                </Fab>
            </div>
            <div className="flex marginBottom20">
                <div className="page-header montserat">
                    Supporters
                </div>
                <div className="support-records-campaign-name montserat flex align-items-center marginLeft15">
                    |&nbsp;&nbsp;&nbsp;{campaign.campaignName}
                </div>
            </div>
            <div className="flex">
                <div className="flex">
                    <div className="flex align-items-center marginRight10">
                        <SortIcon/>
                    </div>
                    <ToggleButtonGroup
                        value={sortBy}
                        exclusive
                        size="small"
                        onChange={(event, newSortBy) => setSortBy(newSortBy || sortBy)}
                        aria-label="Sort By">
                        {SORT_OPTIONS.map((option, index) => (
                            <ToggleButton value={option} aria-label={option} key={`sort-option-${index}`}>
                                {option}
                            </ToggleButton>
                        ))}=
                    </ToggleButtonGroup>
                </div>
                <div className="flex marginLeft45">
                    <div className="flex align-items-center marginRight10">
                        <VisibilityIcon />
                    </div>
                    <ToggleButtonGroup
                        value={visibility}
                        exclusive
                        size="small"
                        onChange={(event, newVisibility) => setVisibility(newVisibility || visibility)}
                        aria-label="Sort By">
                        {VISIBILITY_OPTIONS.map((option, index) => (
                            <ToggleButton value={option} aria-label={option} key={`visibility-option-${index}`}>
                                {option}
                            </ToggleButton>
                        ))}=
                    </ToggleButtonGroup>
                </div>
            </div>
            <div>
                {loading ?
                    <Loading size={45} />
                    :
                    filteredRecords.map((record, index) => (
                        <SupportRecordItem key={`support-record-${index}`} record={record} />
                    ))
                }
                {!loading && !records.length &&
                    <div className="supporters-empty montserat flex center marginTop30 column">
                        <div className="supporters-empty-top">
                            No supporters added today.
                        </div>
                        <div className="marginTop15">
                            Tap '+' to add your first supporter!
                        </div>
                    </div>
                }
            </div>
            {!loading &&
                <>
                    <FullscreenDialog onClose={() => {}} open={!Boolean(todoId) && Boolean(recordId) && recordId !== "stats" && recordId !== "scoty" && recordId !== "contribution" && !Boolean(action)}>
                        <SupportRecordDetails recordId={recordId} />
                    </FullscreenDialog>
                    <FullscreenDialog onClose={() => {}} open={!Boolean(todoId) && Boolean(recordId) && recordId !== "stats" && recordId !== "scoty" && recordId !== "contribution" && Boolean(action)}>
                        <EditSupportRecord recordId={recordId} PageElement={actionElement} />
                    </FullscreenDialog>
                </>
            }
            <FullscreenDialog onClose={() => {}} open={recordId === "stats"}>
                <Stats />
            </FullscreenDialog>
            <FullscreenDialog onClose={() => {}} open={Boolean(document.location.pathname.match("scoty"))}>
                <Scoty />
            </FullscreenDialog>
            <FullscreenDialog onClose={() => {}} open={recordId === "contribution"}>
                <RecurringContribution />
            </FullscreenDialog>
        </div>
    )
};

export default SupportRecords;