import React from 'react';

import TCEDialog from "../../_common/dialog";
import SupporterInfoForm from "../../supportRecords/details/info/form";

const SupporterInfoDialog = ({ showSupporterInfoDialog, setShowSupporterInfoDialog, values, setFieldValue, handleChange, errors, touched }) => {
    return (
        <TCEDialog
            open={showSupporterInfoDialog}
            onClose={() => setShowSupporterInfoDialog(false)}
            title="Supporter Info"
            maxWidth="md"
            actions={[
                {label: 'Done', onClick: () => setShowSupporterInfoDialog(false)},
            ]}>
            <div style={{width: "800px"}}>
                <SupporterInfoForm {...{values, setFieldValue, handleChange, errors, touched}} />
            </div>
        </TCEDialog>
    );
};

export default SupporterInfoDialog;