import SupporterInfo from "../components/supportRecords/details/info";
import Contribution from "../components/supportRecords/details/contribution";
import Survey from "../components/supportRecords/details/survey";

function useComponentForAction(action) {
    let actionElement = SupporterInfo;
    if (action === "contribution") {
        actionElement = Contribution;
    } else if (action === "survey") {
        actionElement = Survey;
    }
    return actionElement;
}

export default useComponentForAction;