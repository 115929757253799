import React from 'react';
import {useDispatch} from "react-redux";
import Fab from "@material-ui/core/Fab";
import Add from '@material-ui/icons/Add';

import {useStatsSelector} from "../../../redux/stats/selectors";
import {decrementClips, decrementContacts, incrementClips, incrementContacts} from "../../../redux/stats/actions";

import './index.css';

const Clickers = () => {
    const stats = useStatsSelector();

    return (
        <div className="clickers-container">
            <ClickerRow label="Contacts" value={stats.contacts} decrement={decrementContacts} increment={incrementContacts} />
            <ClickerRow label="Clips" value={stats.clips} decrement={decrementClips} increment={incrementClips} />
        </div>
    );
};

const ClickerRow = ({value, label, increment, decrement}) => {
    const dispatch = useDispatch();
    return (
        <div className="flex marginBottom15">
            <div className="flex1 flex center marginRight15">
                {label}
            </div>
            <div className="flex marginRight15">
                <div className="marginRight5">
                    <Fab size="medium" color="secondary" aria-label="Subtract" onClick={_ => dispatch(decrement())}>
                        <span className="clicker-minus">&mdash;</span>
                    </Fab>
                </div>
                <div>
                    <Fab size="medium" color="secondary" aria-label="Add" onClick={() => dispatch(increment())}>
                        <Add htmlColor="#ffffff" />
                    </Fab>
                </div>
            </div>
            <div className="clicker-row-value flex center">
                {value}
            </div>
        </div>
    )
};

export default Clickers;