/*global google*/
import {useState, useEffect} from 'react';
import {url} from '../utils/markerIcons';

function useMarkerManager(map, todos, lastUpdated, onMarkerClick, onMarkerDragged) {
    const [markerMap, setMarkerMap] = useState({});

    useEffect(() => {
        if (map && todos && todos.length) {
            const oldIds = Object.keys(markerMap);
            const newTodos = todos.filter(todo => !oldIds.includes(todo.id));
            const ids = todos.map(todo => todo.id);
            const removedIds = oldIds.filter(id => !ids.includes(id));

            const newMap = newTodos.reduce((ret, todo) => {
                const marker = new google.maps.Marker({
                    position: {lat: todo.lat, lng: todo.lng},
                    map,
                    icon: url(todo.color),
                    draggable: !Boolean(todo.supporter)
                });
                marker.addListener('click', () => onMarkerClick(marker, todo.id, todo.supporter));
                google.maps.event.addListener(marker, 'dragend', event => {
                    onMarkerDragged(todo.id, event.latLng.lat(), event.latLng.lng());
                });
                ret[todo.id] = marker;
                return ret;
            }, {...markerMap});

            removedIds.forEach(id => {
                delete newMap[id];
                markerMap[id].setMap(null);
            });

            setMarkerMap(newMap);
        }
    }, [map, todos ? todos.length : 0, lastUpdated]);  // eslint-disable-line react-hooks/exhaustive-deps

    return markerMap;
}

export default useMarkerManager;