import React, {useRef} from 'react';

import Paint from "../paint";

import './index.css';

const DrawingLayer = ({undoRef, clearRef, redoRef, saveRef, drawMode, color, penSize, eraseSize, eraseMode, onSave, canvasImg, showDrawing}) => {
    const imgRef = useRef(null);
    return (
        <div className="drawing-layer-container flex align-items-center">
            {drawMode &&
                <Paint {...{undoRef, clearRef, redoRef, saveRef, penSize, eraseSize, onSave, canvasImg, imgRef}} penColor={color} erase={eraseMode} />
            }
            <img src={canvasImg} alt="canvas preview" className={`scoty-static-image${!showDrawing || drawMode || !canvasImg ? ' hidden' : ''}`} ref={imgRef}/>
        </div>
    )
};

export default DrawingLayer;