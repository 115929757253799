import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import TCEApi from '@devsontap/tce-api';

import IndexedDB from '../utils/IndexedDB';

// TODO: Use the API Key from environemnt variables once the switch is made. Use prod for now.
const GOOGLE_GEOCODING_URL = "https://maps.googleapis.com/maps/api/geocode/json?sensor=false&key=AIzaSyCELP40X2MXGwpkpKv577DqiS2zMAgOWAQ";

function initFirebase() {
    let config = isDev() ?
        { // Dev
            apiKey: "AIzaSyD24KH6iEea44uHsw-CpsefDrgM5wjHAFc",
            authDomain: "tce-canvassers-dev.firebaseapp.com",
            databaseURL: "https://tce-canvassers-dev.firebaseio.com",
            storageBucket: "tce-canvassers-dev.appspot.com",
            projectId: "tce-canvassers-dev"
        }
        :
        { // Prod
            apiKey: "AIzaSyCELP40X2MXGwpkpKv577DqiS2zMAgOWAQ",
            authDomain: "tce-canvassers.firebaseapp.com",
            databaseURL: "https://tce-canvassers.firebaseio.com",
            storageBucket: "tce-canvassers.appspot.com",
            projectId: "tce-canvassers"
        }
    firebase.initializeApp(config);
    firebase.firestore().enablePersistence().catch(err => {
        alert("Error enabling offline storage. Please close Chrome, kill this app, and launch this app again.");
        console.error("ERROR ENABLING FIRESTORE PERSISTENCE", err);
    });
    // firebase.functions().useEmulator("http://localhost", 5002);

}

export function isDev() {
    return document.domain !== 'app.texasenvironment.org' && document.domain !== 'tce-app.web.app' && document.domain !== 'tce-app.firebaseapp.com';
}

initFirebase();
const api = new TCEApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage);

api.getRecaptchaVerifier = (containerId, callback) => {
    return new firebase.auth.RecaptchaVerifier(containerId, {
        size: 'invisible',
        callback
    });
};

api.initiatePhoneAuth = (phoneNumber, appVerifier) => {
    return firebase.auth().signInWithPhoneNumber(`+1${phoneNumber}`, appVerifier);
};

api.logout = () => {
    return firebase.auth().signOut();
};

api.saveSignatureLocal = (id, dataUrl, data) => {
    return IndexedDB.saveImage(id, dataUrl, data);
};

api.getLocalSignature = (id) => {
    return IndexedDB.getImage(id);
};

api.getSignatures = () => {
    return IndexedDB.getSignatures();
};

api.clearLocalData = () => {
    localStorage.clear();
    IndexedDB.clear();
};

api.saveScotyCanvasImage = dataUrl => {
    return IndexedDB.saveScotyCanvasImage(dataUrl);
};

api.getScotyCanvasImage = () => {
    return IndexedDB.getScotyCanvasImage()
        .catch(e => console.error(e));
};

api.geocodeAddress = async (address) => {
    try {
        const response = await fetch(`${GOOGLE_GEOCODING_URL}&address=${address}`);
        const data = await response.json();

        if (data.results.length) {
            const lat = data.results[0].geometry.location.lat;
            const lng = data.results[0].geometry.location.lng;
            return {
                lat,
                lng
            };
        }
    } catch (error) {
        console.error(error);
        return false;
    }

    return false;
};

/**
 * Firebase Auth callback when state changes
 * @param onAuthChanged
 */
// api.onAuthStateChanged = (onAuthChanged) => {
//     firebase.auth().onAuthStateChanged(onAuthChanged);
// };

export default api;