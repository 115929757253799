import React, { useState } from 'react';
import IconButton from "@material-ui/core/IconButton";

import rightArrow from '../../../img/baseline-keyboard_arrow_right-24px.svg';
import leftArrow from '../../../img/baseline-keyboard_arrow_left-24px.svg';

import './index.css';

const Carousel = ({ children }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <div className="carousel-container">
            <div className="carousel-inner-container" style={{
                width: `${children.length}00%`,
                transform: `translate3d(-${(100.0 / (children.length * 1.0)) * currentSlide}%, 0, 0)`
            }}>
                {children.map((child, index) => {
                    return (
                        <div className="carousel-page" style={{width: `${100.0 / (children.length * 1.0)}%`}} key={`page-${index}`}>
                            {child}
                        </div>
                    )
                })}
            </div>
            {currentSlide > 0 ?
                <div className="carousel-left-arrow">
                    <IconButton onClick={() => setCurrentSlide(currentSlide - 1)}><img src={leftArrow} alt="back"/></IconButton>
                </div>
                :
                null
            }
            {currentSlide < children.length - 1 ?
                <div className="carousel-right-arrow">
                    <IconButton onClick={() => setCurrentSlide(currentSlide + 1)}><img src={rightArrow} alt="forward"/></IconButton>
                </div>
                :
                null
            }
            <div className="flex center marginTop20 carousel-indicators">
                {children.map((child, index) => {
                    return (
                        <div key={`indicator-${index}`} className={`carousel-indicator marginRight10${currentSlide === index ? " carousel-indicator-selected" : ""}`} />
                    )
                })}
            </div>
        </div>
    );
};

export default Carousel;