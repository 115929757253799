import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';

import states from './states';

const StateSelect = ({name, value, onStateChanged, showLabel}) => {
    const memoizedOptions = useMemo(() => {
        console.log("rendering memoizedOptions");
        return states.map((state, index)=> {
            return (
                <MenuItem value={state} key={"state-" + index}>{state}</MenuItem>
            )
        })
    }, []);

    return (
        <FormControl fullWidth>
            {showLabel &&
                <InputLabel id="stateCode">State</InputLabel>
            }
            <Select
                name={name}
                value={value}
                onChange={onStateChanged}
                labelId="stateCode"
                fullWidth>
                {memoizedOptions}
            </Select>
        </FormControl>
    )
};

StateSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onStateChanged: PropTypes.func.isRequired
};

StateSelect.defaultProps = {
    name: "stateCode"
};

export default StateSelect;