import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {campaignSelector} from "../redux/campaigns/selectors";
import {orgSelector} from "../redux/organizations/selectors";

export default function useCurrentOrg() {
    const { campaignId } = useParams();
    const campaign = useSelector(campaignSelector(campaignId));
    const org = useSelector(orgSelector(campaign?.orgId));
    return org || {};
}