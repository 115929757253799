import React, {useState, useEffect, useRef} from 'react';
import {Formik, Form} from "formik";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Button from "@material-ui/core/Button";
import Flag from '@material-ui/icons/Flag';
import Close from '@material-ui/icons/Close'
import Save from '@material-ui/icons/Save';
import PersonAdd from '@material-ui/icons/PersonAdd';
import {useDispatch} from "react-redux";
import {useHistory, useParams} from 'react-router-dom';
import {useSnackbar} from "notistack";
import {format} from 'date-fns';

import FlaggedReasonSelect from "../../_common/flaggedReasonSelect";
import CustomFormikField from "../../_common/formik/customField";
import {saveTodo} from "../../../redux/todos";
import {flagSupporter} from "../../../redux/supporters/actions";
import {useTodoSelector} from "../../../redux/todos/selectors";
import {useLoginSelector} from "../../../redux/login/selectors";
import {useSupportRecordDetailsSelector} from "../../../redux/supportRecords/selectors";
import {campaignSelector} from "../../../redux/campaigns/selectors";
import {COLORS, url} from "../../../utils/markerIcons";
import {formatMoneyFromPennies} from "../../../utils/money";

import './index.css';

const ToDo = ({ todoId, marker, onClose, submitRef }) => {
    const [showFlag, setShowFlag] = useState(false);
    const dispatch = useDispatch();
    const { campaignId } = useParams();
    const todo = useTodoSelector(todoId);
    const { canvasser } = useLoginSelector();
    const record = useSupportRecordDetailsSelector(todo ? todo.supportRecordId : '');
    const { hideContributionScreen } = useSelector(campaignSelector(campaignId));
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const setAddressRef = useRef(null);

    const editInfo = () => history.push(document.location.pathname + `/todos/${todoId}/${todo.supportRecordId}/info`);
    const editContribution = () => record.contribution.token ?
        enqueueSnackbar('Sorry, you cannot edit a Contribution after a Subscription has been added.') :
        history.push(document.location.pathname + `/todos/${todoId}/${todo.supportRecordId}/contribution`);
    const editSurvey = () => history.push(document.location.pathname + `/todos/${todoId}/${todo.supportRecordId}/survey`);

    const address = todo ? todo.address : "";

    useEffect(() => {
        if (setAddressRef.current) {
            setAddressRef.current.click();
        }
    },[address]);

    return (
        <div className="todo-container">
            {todo &&
                <Formik
                    initialValues={{
                        address: '',
                        flaggedReason: '',
                        flaggedReasonOther: '',
                        ...todo,
                        note: todo.supporter?.note || '',
                    }}
                    onSubmit={values => {
                        // KBM - kind of a hack, but if we've added a support record, Formik won't have the updated value,
                        // so pull it off the most recent item from redux before saving the todo
                        values.supportRecordId = todo.supportRecordId;
                        dispatch(saveTodo(values));
                        marker.setOptions({
                            icon: url(values.color)
                        });
                        onClose();
                    }} >
                    {({
                          values,
                          handleChange,
                          setFieldValue
                      }) => {
                        return (
                            <Form className="relative">
                                <input type="submit" style={{display: "none"}} ref={submitRef}/>
                                <input type="button" style={{display: "none"}} ref={setAddressRef} onClick={() => setFieldValue('address', todo.address)}/>
                                <div className="flex justify-content-center marginBottom10">
                                    <ToggleButtonGroup
                                        value={values.color}
                                        exclusive
                                        size="medium"
                                        onChange={(event, newColor) => setFieldValue('color', newColor || values.color, false)}
                                        aria-label="Color">
                                        {/* Remove House because that's now used for Callbacks */}
                                        {COLORS.filter(color => color !== "house").map((option, index) => (
                                            <ToggleButton value={option} aria-label={option} key={`marker-color-${index}`}>
                                                <img src={url(option)} alt="marker" />
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </div>
                                <div className="marginTop10">
                                {values.supporter ?
                                    <div className="marginTop20 marginBottom15">
                                        <div className="flex">
                                            <div className="flex1">
                                                <div className="todo-supporter-name">
                                                    {values.supporter.firstName} {values.supporter.lastName}
                                                </div>
                                                <div className="todo-supporter-address">
                                                    {values.supporter.address.line1}
                                                </div>
                                                <div className="todo-supporter-contribution">
                                                    Last Contribution: <span className="todo-supporter-contribution-amount">{formatMoneyFromPennies(values.supporter.lastContributionAmount)}</span> {values.supporter.lastContributionDate ? `(${format(new Date(values.supporter.lastContributionDate), "MM/dd/yyyy")})` : ""}
                                                </div>
                                                <div className="todo-supporter-contribution">
                                                    Largest Contribution: <span className="todo-supporter-contribution-amount">{formatMoneyFromPennies(values.supporter.largestContribution)}</span>
                                                </div>
                                                {(values.supporter.lastLetterTo || values.supporter.lastLetterDate) &&
                                                    <div className="todo-supporter-contribution">
                                                        Last Letters: <span>{values.supporter.lastLetterTo}</span> {values.supporter.lastLetterDate ? `(${format(new Date(values.supporter.lastLetterDate), "MM/dd/yyyy")})` : ""}
                                                    </div>
                                                }
                                            </div>
                                            <div className={`marginLeft20 flex align-items-center${showFlag ? ' hidden' : ''}`}>
                                                <Button variant="text" color="primary" onClick={() => setShowFlag(true)}>
                                                    <Flag className="marginRight5" />Flag Supporter
                                                </Button>
                                            </div>
                                        </div>
                                        {showFlag &&
                                            <div className="flex marginTop15">
                                                <div className="flex1">
                                                    <div>
                                                        <CustomFormikField
                                                            CustomComponent={FlaggedReasonSelect}
                                                            name="flaggedReason" />
                                                    </div>
                                                    {values.flaggedReason === "Other" &&
                                                        <div className="marginTop10">
                                                            <CustomFormikField
                                                                CustomComponent={TextField}
                                                                name="flaggedReasonOther"
                                                                label="Other Reason"
                                                                placeholder="Reason for flagging Supporter..."
                                                                fullWidth={true} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="flex">
                                                    <div>
                                                        <IconButton onClick={() => {setShowFlag(false)}}>
                                                            <Close />
                                                        </IconButton>
                                                    </div>
                                                    <div>
                                                        <IconButton
                                                            disabled={!values.flaggedReason || (values.flaggedReason === "Other" && values.flaggedReasonOther.length < 3)}
                                                            color="secondary"
                                                            onClick={() => {
                                                                const flaggedReason = values.flaggedReason !== "Other" ? values.flaggedReason : values.flaggedReasonOther;
                                                                dispatch(flagSupporter(values.supporter.id, flaggedReason, `${canvasser.firstName} ${canvasser.lastName}`, enqueueSnackbar));
                                                                setShowFlag(false);
                                                                setFieldValue('flaggedReason', '', false);
                                                            }}>
                                                            <Save />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <TextField
                                        name="address"
                                        label="Address"
                                        placeholder="12345 Place St."
                                        value={values.address}
                                        onChange={handleChange}
                                        fullWidth/>
                                }
                                </div>
                                <div className="marginTop10">
                                    <TextField
                                        name="note"
                                        label="Note"
                                        placeholder="Enter note here..."
                                        value={values.note}
                                        onChange={handleChange}
                                        fullWidth/>
                                </div>
                                <div className="marginTop30">
                                    {todo.supportRecordId ?
                                        <div className="flex">
                                            <div className={`marginRight10${hideContributionScreen ? ' flex1' : ''}`}>
                                                <Button variant="contained" color="primary" fullWidth onClick={editInfo}>
                                                    Edit Info
                                                </Button>
                                            </div>
                                            {!hideContributionScreen &&
                                                <div className="flex1">
                                                    <Button variant="contained" color="primary" fullWidth onClick={editContribution}>
                                                        Edit Contribution
                                                    </Button>
                                                </div>
                                            }
                                            <div className={`marginLeft10${hideContributionScreen ? ' flex1' : ''}`}>
                                                <Button variant="contained" color="primary" fullWidth onClick={editSurvey}>
                                                    Edit Survey
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        <Button variant="contained" color="primary" fullWidth onClick={() => history.push(`${document.location.pathname}/todos/${todoId}/add`)}>
                                            <PersonAdd className="marginRight5"/> New Supporter
                                        </Button>
                                    }
                                </div>
                            </Form>
                        )}}
                </Formik>
            }

        </div>
    )
};

export default ToDo;