import React from 'react';

import TCEDialog from "../../../_common/dialog";

const SupportConfirmationDialog = ({ showSupportConfirmation, setShowSupportConfirmation, goToNextSlide }) => (
    <TCEDialog
        open={showSupportConfirmation}
        onClose={() => setShowSupportConfirmation(false)}
        title="Is this work you definitely agree with?"
        actions={[
            {label: 'back', onClick: () => setShowSupportConfirmation(false)},
            {label: 'I agree', onClick: () => { setShowSupportConfirmation(false); goToNextSlide() }}
        ]}>
        Thanks so much!
    </TCEDialog>
);

export default SupportConfirmationDialog;