import {getLocal, saveLocal} from "@devsontap/dot-react-common/core/utils/localStorage";

import {
    USER_AUTHED,
    USER_LOGOUT,
    SET_RECAPTCHA_VERIFIER,
    SET_CONFIRMATION_RESULT,
    CANVASSER_SUCCESS,
    SET_APP_VERSION
} from "./types";

const KEY_LOGGED_IN = "loggedIn";
const KEY_CLAIMS = "claims";
const KEY_CANVASSER = "canvasser";

const INITIAL_STATE = {
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    claims: getLocal(KEY_CLAIMS, true) || {},
    canvasser: getLocal(KEY_CANVASSER, true),
    recaptchaVerifier: null,
    confirmationResult: null,
    appVersion: "??"
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_AUTHED:
            saveLocal(KEY_CLAIMS, action.payload, true);
            saveLocal(KEY_LOGGED_IN, true, false);
            return {
                ...state,
                loggedIn: true,
                claims: action.payload
            };
        case USER_LOGOUT:
            return {
                ...state,
                loggedIn: false,
                claims: {},
                canvasser: null,
                recaptchaVerifier: null,
                confirmationResult: null,
            };
        case SET_RECAPTCHA_VERIFIER:
            return {
                ...state,
                recaptchaVerifier: action.payload
            };
        case SET_CONFIRMATION_RESULT:
            return {
                ...state,
                confirmationResult: action.payload
            };
        case CANVASSER_SUCCESS:
            saveLocal(KEY_CANVASSER, action.payload, true);
            return {
                ...state,
                canvasser: action.payload
            };
        case SET_APP_VERSION:
            return {
                ...state,
                appVersion: action.payload
            };
        default:
            return state;
    }
};

export default reducer;

