import {
    STAT_INCREMENT,
    STAT_DECREMENT,
} from "./types";

const _incrementStat = key => ({ type: STAT_INCREMENT, payload: key });
const _decrementStat = key => ({ type: STAT_DECREMENT, payload: key });

export const incrementContacts = () => _incrementStat("contacts");
export const decrementContacts = () => _decrementStat("contacts");

export const incrementClips = () => _incrementStat("clips");
export const decrementClips = () => _decrementStat("clips");

export const incrementDoors = () => _incrementStat("doors");
export const decrementDoors = () => _decrementStat("doors");
