import {
    USER_AUTHED,
    USER_LOGOUT,
    CANVASSER_SUCCESS,
    SET_APP_VERSION
} from "./types";

import {loadCanvasserTurf} from "../supporters/actions";
import { userLogout as todosUserLogout } from "../todos";
import { userLogout as supportRecordsLogout } from "../supportRecords";
import { logout as callbacksLogout } from "../callbacks";

export const setRecaptchaVerifier = (containerId, verifierCallback, recaptchaCallback) => (
    (dispatch, getState, { api }) => {
        verifierCallback(api.getRecaptchaVerifier(containerId, recaptchaCallback));
        // return dispatch({ type: SET_RECAPTCHA_VERIFIER, payload: recaptchaVerifier });
    }
);

export const initiatePhoneAuth = (phoneNumber, recaptchaVerifier, setConfirmationResult, setLoading, setShowCanvasserNotFoundDialog, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        console.log("initiatePhoneAuth", recaptchaVerifier);
        const isAuthorized = await api.isPhoneNumberAuthorized(phoneNumber);
        if (isAuthorized) {
            return api.initiatePhoneAuth(phoneNumber, recaptchaVerifier)
                .then(confirmationResult => {
                    return setConfirmationResult(confirmationResult);
                })
                .catch(err => {
                    setLoading(false);
                    enqueueSnackbar(err.message, { variant: 'error' });
                });
        } else {
            setLoading(false);
            setShowCanvasserNotFoundDialog(true);
        }
    }
);

export const logout = (history) => (
    (dispatch, getState, { api }) => {
        return api.logout()
            .then(() => {
                console.log("clearing local data");
                history.push('/');
                api.clearLocalData();
                // This kinda sucks, one nice thing we lose when switching to redux-toolkit
                dispatch(todosUserLogout());
                dispatch(supportRecordsLogout());
                dispatch(callbacksLogout());
            });
    }
);

export const userAuthed = (claims, turf, supporters, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: USER_AUTHED, payload: claims });
        return api.getCanvasserByPhoneNumber(claims.phone_number)
            .then(canvasser => {
                dispatch({ type: CANVASSER_SUCCESS, payload: canvasser });
                dispatch(loadCanvasserTurf(canvasser.id, turf ? turf.id : null));
            })
            .catch(err => {
                console.log("error fetching canvasser");
                console.error(err);
                enqueueSnackbar("error fetching canvasser", { variant: "error" });
            });
    }
);

export const userUnauthed = () => ({ type: USER_LOGOUT });

export const loadAppVersion = () => (
    (dispatch, getState, { api }) => {
        const url = `${document.location.protocol}//${document.domain}/manifest.json`;
        return fetch(url)
            .then(async response => {
                const manifest = await response.json();
                return dispatch({ type: SET_APP_VERSION, payload: manifest.version });
            })
            .catch(err => {
                console.error("error fetching app manifest", err);
            })
    }
);