import React, { useRef, useEffect } from 'react';
import TextField from "@material-ui/core/TextField";

import PhoneNumberTextField from "../../../../_common/textfields/phoneNumber";
import StateSelect from "../../../../_common/stateSelect";

const SupporterInfoForm = ({values, handleChange, touched, errors, setFieldValue}) => {
    const {firstName, lastName, email, phone, houseNumber, unitNumber, streetName, address: {city, stateCode, zip}} = values;
    const updateLine1 = (number, name) => setFieldValue('address.line1', `${number} ${name}`);
    const firstNameRef = useRef(null);

    useEffect(() => {
        firstNameRef?.current?.focus();
    }, [firstNameRef]);

    return (
        <>
            <div className="flex marginTop10">
                <div className="flex1">
                    <TextField
                        name="firstName"
                        label="First"
                        placeholder="First Name"
                        onChange={handleChange}
                        value={firstName}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName ? errors.firstName : null}
                        inputProps={{autoCapitalize: "words"}}
                        inputRef={firstNameRef}
                        fullWidth
                        autoFocus />
                </div>
                <div className="flex1 padding5 marginLeft25">
                    <TextField
                        name="lastName"
                        label="Last Name"
                        placeholder="Last Name"
                        onChange={handleChange}
                        value={lastName}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName ? errors.lastName : null}
                        inputProps={{autoCapitalize: "words"}}
                        fullWidth />
                </div>
            </div>
            <div className="flex marginTop10">
                <div className="flex1 padding5">
                    <TextField
                        name="email"
                        label="Email"
                        type="email"
                        placeholder="you@gmail.com"
                        onChange={handleChange}
                        value={email}
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email ? errors.email : null} />
                </div>
                <div className="flex1 padding5 marginLeft25">
                    <PhoneNumberTextField
                        name="phone"
                        label="Phone #"
                        type="tel"
                        placeholder="(512) 555-5555"
                        onChange={handleChange}
                        value={phone}
                        fullWidth
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone ? errors.phone : null} />
                </div>
            </div>
            <div className="flex marginTop10">
                <div className="flex1 padding5">
                    <TextField
                        name="houseNumber"
                        label="Street Number"
                        placeholder="12345"
                        onChange={event => {
                            handleChange(event);
                            updateLine1(event.target.value, streetName);
                        }}
                        type="tel"
                        value={houseNumber}
                        error={Boolean(touched.houseNumber && errors.houseNumber)}
                        helperText={touched.houseNumber && errors.houseNumber ? errors.houseNumber : null}
                        fullWidth />
                </div>
                <div className="flex4 padding5 marginLeft25">
                    <TextField
                        name="streetName"
                        label="Street Name"
                        placeholder="Street Lane"
                        onChange={event => {
                            handleChange(event);
                            updateLine1(houseNumber, event.target.value);
                        }}
                        inputProps={{autoCapitalize: "words"}}
                        value={streetName}
                        error={Boolean(touched.streetName && errors.streetName)}
                        helperText={touched.streetName && errors.streetName ? errors.streetName : null}
                        fullWidth />
                </div>
                <div className="flex1 padding5 marginLeft25">
                    <div>
                        <TextField
                            name="unitNumber"
                            label="Unit #"
                            placeholder="#103"
                            onChange={event => {
                                handleChange(event);
                                setFieldValue('address.line2', event.target.value);
                            }}
                            value={unitNumber}
                            fullWidth />
                    </div>
                </div>
            </div>
            <div className="flex marginTop10">
                <div className="flex2 padding5">
                    <TextField
                        name="address.city"
                        label="City"
                        placeholder="City Name"
                        onChange={handleChange}
                        value={city}
                        error={Boolean(touched.address && touched.address.city && errors.address && errors.address.city)}
                        helperText={touched.address && touched.address.city && errors.address && errors.address.city ? errors.address.city : null}
                        fullWidth />
                </div>
                <div className="padding5 flex column marginLeft25">
                    <StateSelect name="address.stateCode" onStateChanged={handleChange} value={stateCode} showLabel={true} />
                </div>
                <div className="flex1 padding5 marginLeft25">
                    <TextField
                        name="address.zip"
                        label="Zip"
                        placeholder="Zip Code"
                        type="tel"
                        // onChange={event => { updateAddress('zip', event.target.value) }}
                        onChange={handleChange}
                        value={zip}
                        inputProps={{maxLength: 5}}
                        error={Boolean(touched.address && touched.address.zip && errors.address && errors.address.zip)}
                        helperText={touched.address && touched.address.zip && errors.address && errors.address.zip ? errors.address.zip : null}
                        fullWidth />
                </div>
            </div>
        </>
    )
};

export default SupporterInfoForm;