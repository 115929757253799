import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Close from '@material-ui/icons/Close';

import BareDialog from "../../../../_common/dialog/bare";

import './index.css';

const ContributionLevelsDialog = ({ showContributionLevels, setShowContributionLevels, financials_info }) => {
    return (
        <BareDialog
            open={showContributionLevels}
            onClose={() => setShowContributionLevels(false)}
            maxWidth="lg">
            <div style={{backgroundImage: `url(${financials_info}`}} className="contribution-levels-image"/>
            <div className="flex justify-end infographics-close-container">
                <IconButton onClick={() => setShowContributionLevels(false)}>
                    <Close />
                </IconButton>
            </div>
        </BareDialog>
    )
};

export default ContributionLevelsDialog;