import React, {useEffect} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import {loadOrgs} from "../../../redux/organizations";

import './index.css';
import {orgsSelector} from "../../../redux/organizations/selectors";

const OrganizationSelect = ({name = "orgId", value, onChange, error, progressSize, showEmpty}) => {
    const dispatch = useDispatch();
    const orgs = useSelector(orgsSelector);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(loadOrgs(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    return !orgs?.length ?
            <CircularProgress size={progressSize}/>
            :
            <FormControl fullWidth error={error}>
                <InputLabel id="organizationLabel">Organization</InputLabel>
                <Select
                    name={name}
                    value={value}
                    labelId="organizationLabel"
                    onChange={onChange}
                    displayEmpty>
                    {showEmpty &&
                        <MenuItem value="">
                            &nbsp;
                        </MenuItem>
                    }
                    {orgs.map((org, index) => (
                        <MenuItem value={org.id} key={org.id}>
                            {org.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
};

export default OrganizationSelect;