import React, {useState} from 'react';
import {useDispatch} from "react-redux";

import TCEDialog from "../../../_common/dialog";
import SupportRecordSignature from "../signature";
import {cacheSignature} from "../../../../redux/supportRecords";

const SignatureDialog = ({ showSignatureDialog, setShowSignatureDialog, goToNextSlide, signatureRef, values, setFieldTouched }) => {
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();

    const {firstName, lastName, address} = values;

    return (
        <TCEDialog
            open={showSignatureDialog}
            onClose={() => setShowSignatureDialog(false)}
            title="Verify and Sign Away"
            maxWidth="lg"
            actions={[
                {label: 'nope', onClick: () => setShowSignatureDialog(false)},
                {label: 'Yep', onClick: () => {
                    const empty = signatureRef.current.isEmpty();
                    setShowError(empty);
                    setShowSignatureDialog(empty);
                    if (!empty) {
                        dispatch(cacheSignature(signatureRef.current.toDataURL(), {firstName, lastName, address, hidden: false}));
                        goToNextSlide();
                        setFieldTouched('surveyAnswers', false);
                    }
                }}
            ]}>
            <SupportRecordSignature showError={showError} values={values} signatureRef={signatureRef} />
        </TCEDialog>
    )
};

export default SignatureDialog;