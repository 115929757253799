const VERSION = 2;

class IndexedDB {

    constructor() {
        this.indexedDB = window.indexedDB;
    }

    saveScotyCameraImage(dataUrl) {
        return this.saveImage("scoty_camera", dataUrl);
    }

    getScotyCameraImage() {
        return this.getImage("scoty_camera");
    }

    saveScotyCanvasImage(dataUrl) {
        return this.saveImage("scoty_canvas", dataUrl);
    }

    getScotyCanvasImage() {
        return this.getImage("scoty_canvas");
    }

    getSignatures() {
        return this.getAll()
            .then(results => results.filter(result =>
                result.id !== "scoty_canvas" &&
                result.id !== "scoty_camera" &&
                result.id !== "-1" &&
                result.id !== -1)
            );
    }

    saveImage(id, dataUrl, data) {
        return new Promise((resolve, reject) => {
            var open = this.indexedDB.open("TCE", VERSION);

            open.onupgradeneeded = function() {
                var db = open.result;
                db.createObjectStore("ImageStore", {keyPath: "id"});
            };

            open.onsuccess = function() {
                // Start a new transaction
                var db = open.result;
                var tx = db.transaction("ImageStore", "readwrite");
                var store = tx.objectStore("ImageStore");

                // Add some data
                store.put({id: id, dataUrl: dataUrl, data});

                // Close the db when the transaction is done
                tx.oncomplete = function() {
                    db.close();
                    resolve(true);
                };
            }
        })
    }

    getImage(id) {
        return new Promise((resolve, reject) => {
            var open = this.indexedDB.open("TCE", VERSION);

            open.onupgradeneeded = function() {
                var db = open.result;
                db.createObjectStore("ImageStore", {keyPath: "id"});
            };

            open.onsuccess = function() {
                var db = open.result;
                var tx = db.transaction("ImageStore", "readwrite");
                var store = tx.objectStore("ImageStore");

                var getImage = store.get(id);
                getImage.onsuccess = function() {
                    if (getImage.result) {
                        db.close();
                        resolve(getImage.result);
                    } else {
                        reject("no image");
                    }
                }
            }

        });
    }

    getAll() {
        return new Promise((resolve, reject) => {
            var open = this.indexedDB.open("TCE", VERSION);

            open.onupgradeneeded = function() {
                var db = open.result;
                db.createObjectStore("ImageStore", {keyPath: "id"});
            };

            open.onsuccess = function() {
                var db = open.result;
                var tx = db.transaction("ImageStore", "readwrite");
                var store = tx.objectStore("ImageStore");

                var getAll = store.getAll();
                getAll.onsuccess = function() {
                    if (getAll.result) {
                        db.close();
                        resolve(getAll.result);
                    } else {
                        reject("no images");
                    }
                }
            }

        });
    }

    clear() {
        return new Promise((resolve, reject) => {
            var open = this.indexedDB.open("TCE", VERSION);

            open.onupgradeneeded = function() {
                var db = open.result;
                db.createObjectStore("ImageStore", {keyPath: "id"});
            };

            open.onsuccess = function() {
                var db = open.result;
                var tx = db.transaction("ImageStore", "readwrite");
                var store = tx.objectStore("ImageStore");

                var getAll = store.getAll();
                getAll.onsuccess = function() {
                    if (getAll.result) {
                        getAll.result.forEach(item => {
                            store.delete(item.id);
                        });
                        db.close();
                        resolve(true);
                    } else {
                        reject("no images");
                    }
                }
            }

        });
    }

}

let indexedDB = new IndexedDB();

export default indexedDB;