import React, {useState, useEffect} from 'react';
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch} from "react-redux";

import SupporterInfoForm from "./form";
import TCEDialog from "../../../_common/dialog";
import PrivacyPolicyDialog from "../dialogs/privacyPolicy";
import {getUsersAddress} from "../../../../redux/supportRecords";
import useCurrentOrg from "../../../../hooks/useCurrentOrg";

import './index.css';

const SupporterInfo = ({ values, handleChange, touched, errors, setFieldValue }) => {
    const [showPhoneDialog, setShowPhoneDialog] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const dispatch = useDispatch();
    const {phone, houseNumber, streetName, receiveTexts, address: {city, zip}} = values;
    const org = useCurrentOrg();

    useEffect(() => {
        if (!streetName) {
            dispatch(getUsersAddress(results => {
                setFieldValue('streetName', streetName || results.streetName, false);
                setFieldValue('address.line1', `${houseNumber} ${streetName || results.streetName}`, false);
                setFieldValue('address.city', city || results.city, false);
                setFieldValue('address.zip', zip || results.zip, false);
                setFieldValue('address.lat', results.lat, false);
                setFieldValue('address.lng', results.lng, false);
            }));
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="supporter-info-container flex column flex1 padding20">
            <Paper className="supporter-info-card flex1" square>
                <div className="supporter-info-title montserat">
                    {org.strings?.signUp}
                </div>
                <SupporterInfoForm {...{values, setFieldValue, handleChange, touched, errors}} />
                <div className="flex marginTop30">
                    <div>
                        <Checkbox
                            checked={receiveTexts}
                            onChange={event => phone.length === 10 ? setFieldValue('receiveTexts', event.target.checked, false) : setShowPhoneDialog(true)}
                            value="checked" />
                    </div>
                    <div>
                        <div className="montserat supporter-info-receive-text-messages-title">
                            Yes, I want to receive {org.name}’s text messages!
                        </div>
                        <div className="montserat supporter-info-receive-text-messages-subtitle">
                            {receiveTexts ?
                                `${org.name} ACTION TEXTS - AVERAGE ONE PER MONTH. MESSAGE AND DATA RATES MAY APPLY. REPLY ‘STOP’ TO OPT OUT OR ‘HELP’ FOR ASSISTANCE`
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
                <div className="montserat supporter-info-disclaimer marginTop20" onClick={() => setShowPrivacyPolicy(true)}>
                    {org.name.toUpperCase()} RESPECTS YOUR <u>PRIVACY</u>. PERSONAL INFORMATION WILL NEVER BE DISTRIBUTED OR SOLD TO THIRD PARTIES.
                </div>
            </Paper>
            <TCEDialog
                open={showPhoneDialog}
                onClose={() => setShowPhoneDialog(false)}
                title="Want Our Text Messages?"
                actions={[
                    {label: 'ok', onClick: () => setShowPhoneDialog(false)}
                ]}>
                Please enter a valid cell number.
            </TCEDialog>
            <PrivacyPolicyDialog showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy} />
        </div>
    )
};

export default SupporterInfo;