import React from 'react';

import TCEDialog from "../../../_common/dialog";
import {formatMoneyFromPennies} from "../../../../utils/money";
// import useCurrentOrg from "../../../../hooks/useCurrentOrg";
// import {isDev} from "../../../../api";

const ContributionConfirmationDialog = ({ showContributionConfirmation, setShowContributionConfirmation, goToNextSlide, setShowStripeDialog, values, skipSquare }) => {
    const isAndroid = navigator.userAgent.match(/android/i);
    const isCredit = values.contribution.paymentMethod === "credit";
    const isNonZero = values.contribution.amount > 0;
    const recurring = values.contribution.recurring;
    // const org = useCurrentOrg();
    // const env = isDev() ? "dev" : "prod";

    return (
        <TCEDialog
            open={showContributionConfirmation}
            onClose={() => setShowContributionConfirmation(false)}
            title="Confirm Your Amount"
            actions={[
                {label: 'back', onClick: () => setShowContributionConfirmation(false)},
                {label: 'I agree', onClick: () => {
                    setShowContributionConfirmation(false);
                    if (isCredit && isNonZero) {
                        if (recurring) {
                            setShowStripeDialog(true);
                            return;
                        }
                        if (isAndroid && !skipSquare) {
                            // Launch Square Bridge app
                            // document.location.href = `tce://stripe/${env}/${org.id}/${values.contribution.amount}`;
                            document.location.href = `tce://square/${values.contribution.amount}`;
                        }
                        return goToNextSlide();
                    }
                    goToNextSlide();
                }}
            ]}>
            {!values.contribution.recurring ?
                `So we can put you down for ${formatMoneyFromPennies(values.contribution.amount)} in ${values.contribution.paymentMethod}?`
                :
                `So we can put you down for a ${formatMoneyFromPennies(values.contribution.amount)} recurring contribution?`
            }
        </TCEDialog>
    );
};

ContributionConfirmationDialog.defaultProps = {
    skipSquare: false
};

export default ContributionConfirmationDialog;