import React, {useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import * as Yup from 'yup';

import TCEDialog from "../../_common/dialog";
import PhoneNumberTextField from "../../_common/textfields/phoneNumber";
import {initiatePhoneAuth} from "../../../redux/login/actions";

import './index.css'

const LoginForm = ({ verifier, setConfirmationResult }) => {
    const [loading, setLoading] = useState(false);
    const [showCanvasserNotFoundDialog, setShowCanvasserNotFoundDialog] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {values, errors, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string().length(10, "Please enter a valid phone number").required(),
        }),
        onSubmit: values => {
            setLoading(true);
            dispatch(initiatePhoneAuth(values.phone, verifier, setConfirmationResult, setLoading, setShowCanvasserNotFoundDialog, enqueueSnackbar));
        }
    });

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-align-left login-title roboto">
                Sign In
            </div>
            <div className="login-row">
                <div>
                    <PhoneNumberTextField
                        name="phone"
                        type="tel"
                        placeholder="(512) 444-3333"
                        label="Phone Number"
                        value={values.phone}
                        onChange={handleChange}
                        error={errors.phone && touched.phone}
                        helperText={errors.phone && touched.phone ? errors.phone : null}
                        inputProps={{autoComplete: 'off'}}
                        fullWidth />
                </div>
            </div>
            <div className="login-buttons">
                {loading ?
                    <div className="flex justify-end">
                        <CircularProgress size={40} />
                    </div>
                    :
                    <div className="flex justify-end">
                        <div>
                            <Button variant="contained" color="secondary" type="submit">Login</Button>
                        </div>
                    </div>
                }
            </div>
            <TCEDialog
                open={showCanvasserNotFoundDialog}
                title={"Phone Number Not Found"}
                onClose={() => setShowCanvasserNotFoundDialog(false)}
                actions={[{ label: 'OK', onClick: () => setShowCanvasserNotFoundDialog(false)}]}>
                The phone number entered was not found. Please try again.
            </TCEDialog>
        </form>
    )
};

export default LoginForm;