import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { getLocal, saveLocal } from '@devsontap/dot-react-common/core/utils/localStorage';

import { geocodeUsersLocation } from "../../utils/reverseGeocode";
import { saveTodo } from "../todos";

const KEY_SUPPORT_RECORD_IDS = "SUPPORT_RECORD_IDS";
const KEY_CACHED_SUPPORT_RECORD = "CACHED_SUPPORT_RECORD";
const KEY_HIDDEN_IDS = "HIDDEN_IDS";

const INITIAL_STATE = {
    cachedRecord: getLocal(KEY_CACHED_SUPPORT_RECORD, true),
    recordMap: {},
    ids: getLocal(KEY_SUPPORT_RECORD_IDS, true) || [],
    hiddenIds: getLocal(KEY_HIDDEN_IDS, true) || {},
    lastUpdated: null
};

const supportRecordsSlice = createSlice({
    name: 'supportRecords',
    initialState: INITIAL_STATE,
    reducers: {
        supportRecordsSuccess(state, action) {
            state.recordMap = action.payload.reduce((ret, record) => ({ ...ret, [record.id]: record }), state.recordMap);
        },
        supportRecordSuccess(state, action) {
            state.recordMap[action.payload.id] = action.payload;
            state.ids = Object.keys(state.recordMap);
            state.lastUpdated = new Date().getTime();
            saveLocal(KEY_SUPPORT_RECORD_IDS, state.ids, true);
        },
        cacheSupportRecord(state, action) {
            state.cachedRecord = action.payload;
            saveLocal(KEY_CACHED_SUPPORT_RECORD, action.payload, true);
        },
        clearCachedSupportRecord(state) {
            state.cachedRecord = null;
            saveLocal(KEY_CACHED_SUPPORT_RECORD, null);
        },
        hideSupportRecordSignature(state, action) {
            state.hiddenIds[action.payload] = true;
            saveLocal(KEY_HIDDEN_IDS, state.hiddenIds, true);
        },
        showSupportRecordSignature(state, action) {
            delete state.hiddenIds[action.payload];
            saveLocal(KEY_HIDDEN_IDS, state.hiddenIds, true);
        },
        userLogout(state) {
            state.cachedRecord = null;
            state.recordMap = {};
            state.ids = [];
            state.hiddenIds = {};
        }
    }
});

export const {
    supportRecordsSuccess, supportRecordSuccess, cacheSupportRecord, clearCachedSupportRecord,
    hideSupportRecordSignature, showSupportRecordSignature, userLogout
} = supportRecordsSlice.actions;

export default supportRecordsSlice.reducer;

// CUSTOM THUNK ACTIONS

export const saveSupportRecord = (supportRecord, goBack, uploadSignature = true, todo = null, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        if (todo) {
            // This is an item from the survey screen that hasn't been 'created' yet
            if (!todo.id) {
                supportRecord.todoId = uuidv4();
                todo = {
                    ...todo,
                    id: supportRecord.todoId
                }
            } else {
                supportRecord.todoId = todo.id;
            }

            if (!todo.address) {
                todo = {
                    ...todo,
                    address: supportRecord.address.line1
                };
            }

            const coords = await api.geocodeAddress(`${supportRecord.address.line1} ${supportRecord.address.city}, ${supportRecord.address.stateCode} ${supportRecord.address.zip}`);
            if (coords.lat && coords.lng) {
                todo = {
                    ...todo,
                    lat: coords.lat,
                    lng: coords.lng
                };

                supportRecord.address.lat = coords.lat;
                supportRecord.address.lng = coords.lng;
            }

            if (!todo.lat) {
                return enqueueSnackbar("ToDo not saved - could not find lat/lng.", {variant: "error"});
            }
        }

        // createdAt might have been converted to string when persisted to disk, so make sure we're sending a Date back
        if (typeof supportRecord.createdAt == "string") {
            supportRecord.createdAt = new Date(supportRecord.createdAt);
        }

        return api.saveSupportRecord(supportRecord)
            .then(result => {
                dispatch(supportRecordSuccess(result));
                dispatch(clearCachedSupportRecord());
                if (uploadSignature) {
                    dispatch(uploadSupportRecordSignature(result));
                }
                if (todo) {
                    dispatch(saveTodo({...todo, supportRecordId: result.id}));
                }
                return goBack();
            })
            .catch(err => {
                console.error(err);
            });
    }
);

export const cacheSignature = (dataUrl, data) => (
    (dispatch, getState, { api }) => {
        return api.saveSignatureLocal(-1, dataUrl, data)
            .then(result => console.log("SIGNATURE CACHED!"));
    }
);

export const uploadSupportRecordSignature = (payload) => (
    async (dispatch, getState, { api }) => {
        // Get cached siggy
        const siggy = await api.getLocalSignature(-1);

        // Save cached siggy with correct id
        await api.saveSignatureLocal(payload.id, siggy.dataUrl, siggy.data);

        // Upload signature
        const signatureUrl = await api.uploadSignature(payload.id, siggy.dataUrl);

        console.log("siggy download url", signatureUrl);

        // Update Support Record with DownloadUrl
        return dispatch(saveSupportRecord({...payload, signatureUrl}, () => {}, false));
    }
);

export const createSubscription = (email, amount, token, interval_count, orgId, callback) => (
    (dispatch, getState, { api }) => {
        return api.createStripeSubscription(email, amount, token, interval_count, orgId)
            .then(response => {
                callback({ response });
            })
            .catch(error => {
                console.error("createSubscription error", error);
                callback({ error });
            })
    }
);

export const getCachedSignatures = (callback) => (
    (dispatch, getState, { api }) => {
        return api.getSignatures()
            .then(signatures => {
                console.log("found", signatures.length, "signatures");
                callback(signatures);
            });
    }
);

export const inflateSupportRecords = (ids, setLoading) => (
    (dispatch, getState, { api }) => {
        return Promise.all(ids.map(id => api.getSupportRecord(id)))
            .then(supportRecords => {
                setLoading(false);
                dispatch(supportRecordsSuccess(supportRecords));
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            })
    }
);

export const getUsersAddress = (callback) => (
    (dispatch, getState, { api }) => {
        return geocodeUsersLocation()
            .then(results => {
                console.log("getUsersAddress", results);
                callback(results);
            })
            .catch(err => {
                console.error("getUsersAddress", err);
            })
    }
);