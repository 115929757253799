import React from 'react';
import Switch from "@material-ui/core/Switch";
import {useDispatch} from "react-redux";

import {COLORS, url} from "../../../utils/markerIcons";
import {toggleColor} from "../../../redux/todos";

import './index.css';

const ColorToggles = ({ colorVisibility }) => {
    const dispatch = useDispatch();

    return (
        <div>
            {COLORS.map(color => (
                <div key={color} className="flex">
                    <div>
                        <img src={url(color)} alt="color" />
                    </div>
                    <div>
                        <Switch
                            color="secondary"
                            checked={Boolean(colorVisibility[color])}
                            onChange={event => dispatch(toggleColor(color))} />
                    </div>
                </div>
            ))}
        </div>
    )
};

export default ColorToggles;