import React from 'react';
import Paper from '@material-ui/core/Paper';

import './index.css';

const ImgSlide = ({ url }) => {
    return (
        <div className="img-slide-container h100 flex column">
            <div className="img-slide-inner flex1 flex column">
                <Paper className="img-slide-paper flex1">
                    <div className="img-slide-img h100" style={{backgroundImage: `url(${url}`}} />
                </Paper>
            </div>
        </div>
    )
};

export default ImgSlide;