/*global Stripe*/
import {useState, useEffect} from 'react';
import {useSelector} from "react-redux";

import {orgSelector} from "../redux/organizations/selectors";

export const PROD_DOMAINS = [
    "app.texasenvironment.org",
    // TODO: Remove this when done testing in DEV
    "dev.app.texasenvironment.org",
    "tce-app.web.app",
    "tce-app.firebaseapp.com",
];

const useStripe = (cardElementId, orgId) => {
    const [stripe, setStripe] = useState({});
    const org = useSelector(orgSelector(orgId));

    useEffect(() => {
        if (org) {
            const client = Stripe(
                PROD_DOMAINS.includes(document.domain) ?
                    org.stripeApiKey :
                    org.stripeTestApiKey
            );

            // Create an instance of Elements.
            var elements = client.elements();

            // Custom styling can be passed to options when creating an Element.
            // (Note that this demo uses a wider set of styles than the guide below.)
            const style = {
                base: {
                    color: '#32325d',
                    lineHeight: '18px',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            };

            const card = elements.create('card', {style});
            card.mount(cardElementId);

            setStripe({ client, card });
        }
    }, [cardElementId, org]);

    return stripe;
};

export default useStripe;