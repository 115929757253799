import React, { useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {useDispatch, useSelector} from "react-redux";
import { isFundContribution, didAgreeToWriteLetters, hasCollectedLetters } from '@devsontap/tce-api/core/utils';

import TCEDialog from "../../_common/dialog";
import { formatMoneyFromPennies } from "../../../utils/money";
import { useSupportRecordsSelector } from "../../../redux/supportRecords/selectors";
import { campaignSelector } from "../../../redux/campaigns/selectors";
import { hideSupportRecordSignature, showSupportRecordSignature } from "../../../redux/supportRecords";

import recurringIcon from '../../../img/baseline-repeat-24px.svg';
import checkIcon from '../../../img/mark_email_read_black_24dp.svg';
import lettersIcon from '../../../img/mail_black_24dp.svg';

import './index.css';

const SupportRecordItem = ({record}) => {
    const [showCannotEditDialog, setShowCannotEditDialog] = useState(false);
    const { hiddenIds } = useSupportRecordsSelector();
    const { campaignId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { hideContributionScreen } = useSelector(campaignSelector(campaignId));

    const editInfo = () => history.push(`/campaigns/${campaignId}/${record.id}/info`);
    const editContribution = () => record.contribution.token ? setShowCannotEditDialog(true) : history.push(`/campaigns/${campaignId}/${record.id}/contribution`);
    const editSurvey = () => history.push(`/campaigns/${campaignId}/${record.id}/survey`);

    return (
        <>
            <Paper className="text-align-left support-record-card marginTop20" square>
                <div className="flex column">
                    <div className="flex1">
                        <div className="flex">
                            <div className="montserat supporter-address">
                                {record.address.line1}
                            </div>
                            <div className="montserat supporter-created marginLeft20">
                                {format(record.createdAt, 'MM/dd/yyyy')}
                            </div>
                            {/*<div className="flex justifyEnd flex1">*/}
                            {/*    <div>*/}
                            {/*        <img src={this.props.supporter.uploaded ? checkIcon : syncIcon} alt="sync status" className="supporter-sync-icon" />*/}
                            {/*    </div>*/}
                            {/*    <div className="flex center supporter-sync-status marginLeft5">*/}
                            {/*        {this.props.supporter.uploaded ? "Uploaded" : "Uploading"}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="flex">
                            <div className="montserat supporter-name">
                                {`${record.lastName}, ${record.firstName}`}
                            </div>
                            <div className="marginLeft10 marginRight10 marginTop5">
                                |
                            </div>
                            <div className="montserat supporter-name">
                                {`${record.email || "No Email"}`}
                            </div>
                            <div className="marginLeft10 marginRight10 marginTop5">
                                |
                            </div>
                            <div className="montserat supporter-name">
                                {`${record.phone || "No Phone"}`}
                            </div>
                            <div className="marginLeft10 marginRight10 marginTop5">
                                |
                            </div>
                            <div className="montserat supporter-name">
                                {record.contribution && record.contribution.amount > 0 ?
                                    <div className="flex">
                                        <div>
                                            {_getContributionAmount(record.contribution)}
                                        </div>
                                        <div className="marginLeft5">
                                            {(record.contribution.token ? <img src={recurringIcon} alt="recurring" /> : null)}
                                        </div>
                                    </div>
                                    : "No Contribution"
                                }
                            </div>
                            {record.contribution && record.contribution.amount > 0 ?
                                <div className="flex">
                                    <div className="marginLeft10 marginRight10 marginTop5">
                                        |
                                    </div>
                                    <div className="montserat supporter-name">
                                        {isFundContribution(record.surveyAnswers) ? "TCE Fund" : "TCE"}
                                    </div>
                                </div>
                                :
                                null
                            }
                            {didAgreeToWriteLetters(record.surveyAnswers, record) || hasCollectedLetters(record.surveyAnswers, record) ?
                                <div className="flex">
                                    <div className="marginLeft10 marginRight10 marginTop5">
                                        |
                                    </div>
                                    <div className="montserat supporter-name">
                                        <img src={didAgreeToWriteLetters(record.surveyAnswers, record) ? lettersIcon : checkIcon} alt="letters icon" className="supporter-letters-icon" />
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="flex marginTop5">
                        <div className="flex1 montserat supporter-hide-info">
                            <Switch
                                checked={Boolean(hiddenIds[record.id])}
                                onChange={event => event.target.checked ?
                                    dispatch(hideSupportRecordSignature(record.id)) :
                                    dispatch(showSupportRecordSignature(record.id))}
                                value="hide"
                                color="secondary" />
                            Hide Supporter Info
                        </div>
                        <div className="flex justifyEnd">
                            <Button variant="text" color="secondary" onClick={editInfo}>Edit Info</Button>
                        </div>
                        <div className="flex justifyEnd">
                            <Button variant="text" color="secondary" onClick={editSurvey}>Edit Survey</Button>
                        </div>
                        {!hideContributionScreen &&
                            <div className="flex justifyEnd">
                                <Button variant="text" color="secondary" onClick={editContribution}>Edit Contribution</Button>
                            </div>
                        }
                    </div>
                </div>
            </Paper>
            <TCEDialog
                open={showCannotEditDialog}
                title="Cannot Edit Contribution"
                onClose={() => setShowCannotEditDialog(false)}
                actions={[{ label: "Got It", onClick: () => setShowCannotEditDialog(false) }]}>
                Sorry, you cannot edit a Contribution after a Subscription has been added.
            </TCEDialog>
        </>
    )
};

function _getContributionAmount(contribution) {
    if (contribution.token) {
        // TODO: Put these intervals in a data structure that can be queried
        let interval = "monthly";
        let amount = contribution.amount * 6;
        if (contribution.interval_count === 3) {
            interval = "quarterly";
            amount = contribution.amount * 2;
        }
        return `${formatMoneyFromPennies(contribution.amount)} ${interval} = ${formatMoneyFromPennies(amount)}`
    }
    return `${formatMoneyFromPennies(contribution.amount)} ${contribution.paymentMethod}`
}

export default SupportRecordItem;