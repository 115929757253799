import React, { useRef, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {SurveyQuestionTypes, LetterStatus} from '@devsontap/tce-api/core/models/enums';

import CustomFormikField from "../../../_common/formik/customField";
import SurveyOptions from "./options";
import OrganizationButtonGroup from "../../../_common/organizationButtonGroup";
import {COLORS, url} from "../../../../utils/markerIcons";

import './index.css';

const Survey = ({ survey, values: { surveyAnswers, todoId, addTodo, todoColor, todoNote, address, letterStatus, contribution, orgId }, errors: { surveyAnswers: answerErrors }, touched: { surveyAnswers: touchedAnswers }, setFieldValue }) => {
    const numLettersRef = useRef(null);

    const updateSurveyAnswer = (answer, index) => {
        setFieldValue(`surveyAnswers[${index}].answer`, answer, true);
    };

    const updateMultiChoiceSurveyAnswer = (checked, value, index) => {
        const choices = surveyAnswers[index].answer.split(",");
        if (checked) {
            choices.push(value);
        } else {
            choices.splice(choices.indexOf(value), 1);
        }
        updateSurveyAnswer(choices.join(", "), index);
    };

    useEffect(() => {
        if (letterStatus === LetterStatus.COLLECTED) {
            numLettersRef?.current?.focus()
        }
    }, [letterStatus]);

    return (
        <div className="survey-container flex1 flex column">
            {!todoId.length && address.lat && address.lng &&
                <Paper className="survey-todo-card">
                    <div className="flex">
                        <div className="survey-todo-header flex align-items-center">
                            Add To-Do Map Pin
                        </div>
                        <div className="flex align-items-center">
                            <Switch checked={addTodo} onChange={event => setFieldValue('addTodo', event.target.checked, false)} />
                        </div>
                    </div>
                    {addTodo &&
                        <div>
                            <div className="flex marginTop20">
                                <ToggleButtonGroup
                                    value={todoColor}
                                    exclusive
                                    size="medium"
                                    onChange={(event, newColor) => setFieldValue('todoColor', newColor || todoColor, false)}
                                    aria-label="Color">
                                    {COLORS.map((option, index) => (
                                        <ToggleButton value={option} aria-label={option} key={`marker-color-${index}`}>
                                            <img src={url(option)} alt="marker" />
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </div>
                            <div className="marginTop10">
                                <TextField
                                    name="todoNote"
                                    label="Note"
                                    placeholder="Enter a note..."
                                    value={todoNote}
                                    onChange={event => setFieldValue('todoNote', event.target.value, false)}
                                    fullWidth />
                            </div>
                        </div>
                    }
                </Paper>
            }
            {contribution?.amount > 0 &&
                <Paper className="p-6 mb-5">
                    <div className="mb-4 font-bold text-lg">
                        Which organization was the contribution made towards?
                    </div>
                    <div>
                        <CustomFormikField
                            CustomComponent={OrganizationButtonGroup}
                            onChange={(event, org) => setFieldValue("orgId", org || orgId, false)}
                            name="orgId" />
                    </div>
                </Paper>
            }
            <Paper className="p-6 mb-5">
                <div className="mb-4 font-bold text-lg">
                    Did this supporter agree to write letters?
                </div>
                <ToggleButtonGroup
                    value={letterStatus}
                    name="letterStatus"
                    exclusive
                    size="medium"
                    onChange={(event, status) => setFieldValue('letterStatus', status || letterStatus, false)}
                    aria-label="Letter Status">
                    {Object.values(LetterStatus).map((value, index) => (
                        <ToggleButton value={value} aria-label={value} key={`letter-status-${index}`}>
                            {value}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                {letterStatus === LetterStatus.COLLECTED &&
                    <div className="mt-4">
                        <CustomFormikField
                            CustomComponent={TextField}
                            name="numLettersWritten"
                            label="How many letters did this supporter write?"
                            placeholder="1"
                            type="tel"
                            hideError={true}
                            inputRef={numLettersRef}
                            fullWidth />
                    </div>
                }
            </Paper>
            <Paper className="flex column flex1 survey-card">
                {survey.map((question, index) => {
                    return (
                        <div key={`survey-question-${index}`} className="marginBottom30">
                            <div className="montserat survey-question-text">
                                {question.questionText}
                            </div>
                            <div>
                                {question.type === SurveyQuestionTypes[0].value ?
                                    <CustomFormikField
                                        CustomComponent={TextField}
                                        name={`surveyAnswers[${index}].answer`}
                                        label="Answer"
                                        placeholder="Enter the response here"
                                        hideError={true}
                                        fullWidth />
                                    :
                                    <CustomFormikField
                                        CustomComponent={SurveyOptions}
                                        name={`surveyAnswers[${index}].answer`}
                                        questionIndex={index}
                                        {...{question, updateSurveyAnswer, updateMultiChoiceSurveyAnswer}}  />
                                }
                            </div>
                        </div>
                    )
                })}
            </Paper>
        </div>
    )
};

export default Survey;