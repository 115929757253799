import {getLocal, saveLocal} from "@devsontap/dot-react-common/core/utils/localStorage";

import {
    SUPPORTERS_SUCCESS,
    TURF_SUCCESS
} from "./types";
import {USER_LOGOUT} from "../login/types";

const KEY_TURF = "turf";
const KEY_TURF_MAP = "turfMap";
const KEY_SUPPORTERS = "supporters";

const INITIAL_STATE = {
    turf: getLocal(KEY_TURF, true),
    turfMap: getLocal(KEY_TURF_MAP, true) || {},
    supporters: getLocal(KEY_SUPPORTERS, true) || [],
    lastUpdate: null
};

const reducer = (state = INITIAL_STATE, action) => {
    let turfMap = null;
    let supporters = null;
    switch (action.type) {
        case TURF_SUCCESS:
            turfMap = {
                ...state.turfMap,
                [action.payload.id]: action.payload
            };
            saveLocal(KEY_TURF_MAP, turfMap, true);
            saveLocal(KEY_TURF, action.payload, true);
            return {
                ...state,
                turf: action.payload,
                turfMap
            };
        case SUPPORTERS_SUCCESS:
            supporters = (state.supporters || []).concat(action.payload);
            saveLocal(KEY_SUPPORTERS, supporters, true);
            return {
                ...state,
                supporters
            };
        case USER_LOGOUT:
            return {
                turf: null,
                turfMap: {},
                supporters: null,
                lastUpdate: null,
            };
        default:
            return state;
    }
};

export default reducer;
