export const COLORS = ["green", "yellow", "pink", "brown", "red", "clock", "letter", "house"];
export const url = color => {
    switch (color) {
        case "blue":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fblue-dot.png?alt=media&token=f955f2e8-8a35-45b5-a845-83300aacfdcc";
        case "yellow":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Fyellow-dot-3.png?alt=media&token=ed812841-398f-4717-a036-3afafb78b7b4";
        case "orange":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Forange-dot.png?alt=media&token=3b57433e-0533-4c32-a474-70494ef5fe82";
        case "clock":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Fblue-clock-3.png?alt=media&token=c4bd1b49-8f87-438e-8cae-685e004b8d6d";
        case "letter":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Fpurple-envelope-3.png?alt=media&token=e345481c-943c-4020-93a2-6fd06b5ff0d8";
        case "house":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Forange-house-3.png?alt=media&token=cc0edabe-83d6-437f-a94b-0cf76b1aeb83";
        case "pink":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Fpink-dot-3.png?alt=media&token=5e5186d9-60c4-498e-a8bd-b7f22e7dd898";
        case "brown":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Fbrown-dot-3.png?alt=media&token=bb3bfb9a-948e-4795-b48e-f82e6f46c56d";
        case "red":
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Fred-dot-3.png?alt=media&token=0ffef62e-03f7-4bdb-af52-67986f2af694";
        case "green":
        default:
            return "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/markers%2Fnew%2Fgreen-person-3.png?alt=media&token=4bc87e11-e9f6-424a-9df5-2e4088fc5a43";
    }
};