import React from 'react';

import TCEDialog from "../../../_common/dialog";
import StripeCCInfo from "../contribution/stripe";

const StripeDialog = ({ showStripeDialog, setShowStripeDialog, values, handleChange, setFieldValue, goToNextSlide }) => (
    <TCEDialog
        open={showStripeDialog}
        onClose={() => setShowStripeDialog(false)}
        title="Enter Payment Info"
        maxWidth="md"
        actions={[{label: 'Cancel', onClick: () => setShowStripeDialog(false)}]}>
        <StripeCCInfo {...{values, handleChange, setFieldValue, setShowStripeDialog, goToNextSlide}} />
    </TCEDialog>
);

export default StripeDialog;