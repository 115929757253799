import React from 'react';
import TextField from "@material-ui/core/TextField";

import {formatMoneyFromPennies} from "../../../utils/money";

const TenderEntryTextField = ({ value, onChange, ...otherProps }) => {
    const handleFormattedChange = event => {
        if (event.target.value === "0.0") {
            event.target.value = '';
        } else if (event.target.value.length > 0) {
            const pennies = parseInt(event.target.value.substring(0, 12).replace(/[^0-9]/g, ""), 10);
            event.target.value = pennies;
        }
        onChange(event);
    };

    return (
        <TextField
            onChange={handleFormattedChange}
            value={Boolean(value) ? formatMoneyFromPennies(value, false) : ''}
            {...otherProps} />
    )
};

export default TenderEntryTextField;
