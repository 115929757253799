import React, {useState} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import Check from "@material-ui/icons/Check";
import Switch from "@material-ui/core/Switch";
import {Formik, Form} from "formik";
import {useParams, useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {SCHEMA as supportRecordSchema} from '@devsontap/tce-api/core/models/supportRecord';

import ContributionConfirmationDialog from "../details/dialogs/contributionConfirmation";
import ContributionErrorDialog from "../details/dialogs/contributionError";
import Contribution from "../details/contribution";
import StripeDialog from "../details/dialogs/stripe";
import Logo from "../../_common/logo";
import {campaignSelector} from "../../../redux/campaigns/selectors";
import {useSupportRecordDetailsSelector} from "../../../redux/supportRecords/selectors";
import {saveSupportRecord} from "../../../redux/supportRecords";
import {COLORS} from "../../../utils/markerIcons";
import {useTodoSelector} from "../../../redux/todos/selectors";
import {createTodo} from "../../../redux/todos";

const EditSupportRecord = ({ recordId, PageElement }) => {
    const [showContributionConfirmation, setShowContributionConfirmation] = useState(false);
    const [showContributionError, setShowContributionError] = useState(false);
    const [showStripeDialog, setShowStripeDialog] = useState(false);
    const [showQuickAmounts, setShowQuickAmounts] = useState(false);
    const {campaignId, action, todoId} = useParams();
    const supportRecord = useSupportRecordDetailsSelector(recordId);
    const {survey} = useSelector(campaignSelector(campaignId));
    let todo = useTodoSelector(todoId);
    const history = useHistory();
    const dispatch = useDispatch();

    console.log("support record", supportRecord, recordId);

    const validateContribution = (values, validateForm, handleSubmit) => {
        validateForm().then(errors => {
            if (errors.contribution) {
                setShowContributionError(true)
            } else if (values.contribution.amount > 0) {
                setShowContributionConfirmation(true)
            } else {
                handleSubmit();
            }
        });
    };

    // const goBack = () => history.push(document.location.pathname.replace(`${todoId ? "/" + todoId : ""}/${recordId}/${action}`, ""));
    const goBack = () => history.goBack();
    const onLogoClick = () => goBack();

    if (!supportRecord) {
        if (recordId && action) {
            console.log("going back", recordId, action);
            goBack();
        }
        return null;
    }

    return (
        <div className="flex column h100">
            <AppBar position="static" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        <div className="flex flex1 text-align-left align-items-center" onClick={onLogoClick}>
                            <Logo />
                        </div>
                        {PageElement === Contribution ?
                            <div className="flex column justify-content-center">
                                <Switch
                                    checked={showQuickAmounts}
                                    onChange={() => setShowQuickAmounts(!showQuickAmounts)}
                                    value="hide"
                                    color="secondary" />
                            </div>
                            :
                            null
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <Formik
                initialValues={supportRecord ? {
                    todoId: todo ? todo.id : '',
                    addTodo: false,
                    todoColor: COLORS[0],
                    todoNote: '',
                    ...supportRecord,
                    contribution: {
                        ...supportRecord.contribution,
                        recurring: false // KBM - we can assume recurring is false because subscription contributions can't be edited
                    },
                    canvasser: {
                        ...supportRecord.canvasser,
                        createdAt: supportRecord.canvasser.createdAt.toDate ? supportRecord.canvasser.createdAt.toDate() : supportRecord.canvasser.createdAt
                    }
                } : {}}
                onSubmit={values => {
                    if (values.addTodo) {
                        todo = createTodo(values.address.lat, values.address.lng, values.todoNote, values.todoColor);
                    }
                    dispatch(saveSupportRecord(values, history.goBack, false, todo))
                }}
                validationSchema={supportRecordSchema}>
                {({values, handleChange, setFieldValue, handleSubmit, touched, errors, validateForm}) => {
                    return (
                        <Form className="flex1 home-main-content scrollY">
                            <PageElement {...{survey, values, handleChange, touched, errors, setFieldValue, showQuickAmounts}} />
                            <div className="support-record-details-next-button-container">
                                <Fab color="secondary" aria-label="Next" onClick={action === "contribution" ? () => validateContribution(values, validateForm, handleSubmit) : handleSubmit}>
                                    <Check htmlColor="#ffffff" />
                                </Fab>
                            </div>
                            <ContributionConfirmationDialog {...{showContributionConfirmation, setShowContributionConfirmation, values, setShowStripeDialog}} goToNextSlide={handleSubmit} />
                            <ContributionErrorDialog {...{showContributionError, setShowContributionError}} />
                            <StripeDialog {...{showStripeDialog, setShowStripeDialog, handleChange, values, setFieldValue}} goToNextSlide={handleSubmit} />
                        </Form>
                    )
                }}

            </Formik>
        </div>
    )
};

export default EditSupportRecord;