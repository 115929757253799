import {getLocal, saveLocal} from '@devsontap/dot-react-common/core/utils/localStorage';
import {createSlice} from "@reduxjs/toolkit";

const KEY = "campaigns_v2";
const cachedCampaigns = getLocal(KEY, true);

const INITIAL_STATE = {
    campaignsLoading: false,
    campaignMap: (cachedCampaigns || []).reduce((map, campaign) => ({[campaign.id]: campaign, ...map}), {}),
};


const campaignsSlice = createSlice({
    name: 'campaigns',
    initialState: INITIAL_STATE,
    reducers: {
        campaignsLoading(state, action) {
            state.campaignsLoading = action.payload;
        },
        campaignLoading(state, action) {
            state.campaignLoading = action.payload;
        },
        campaignSaving(state, action) {
            state.campaignSaving = action.payload;
        },
        campaignsSuccess(state, action) {
            state.campaignsLoading = false;
            state.campaignMap = action.payload.reduce((ret, campaign) => ({
                ...ret,
                [campaign.id]: campaign
            }), state.campaignMap);
            saveLocal(KEY, action.payload, true);
        },
        campaignSuccess(state, action) {
            state.campaignLoading = false;
            state.campaignSaving = false;
            state.campaignMap[action.payload.id] = action.payload;
            saveLocal(KEY, Object.values(state.campaignMap), true);
        }
    }
});

export const { campaignsLoading, campaignLoading, campaignsSuccess, campaignSuccess } = campaignsSlice.actions;

export default campaignsSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadCampaigns = () => (
    (dispatch, getState, { api }) => {
        dispatch(campaignsLoading(true));
        return api.getCampaigns()
            .then(campaigns => {
                return dispatch(campaignsSuccess(campaigns.filter(campaign => !campaign.disabled)));
            })
            .catch(err => {
                console.error(err);
                return dispatch(campaignsLoading(false));
            })
    }
);


export const loadCampaign = (campaignId) => (
    (dispatch, getState, { api }) => {
        dispatch(campaignLoading(true));
        return api.getCampaign(campaignId)
            .then(campaign => {
                return dispatch(campaignSuccess(campaign));
            })
            .catch(err => {
                console.error(err);
                return dispatch(campaignLoading(false));
            })
    }
);