import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Close from '@material-ui/icons/Close';

import BareDialog from "../../../../_common/dialog/bare";
import Carousel from "../../../../_common/carousel";

import './index.css';

const InfographicsDialog = ({ showInfographicsDialog, setShowInfographicsDialog, infographics }) => {
    return (
        <BareDialog
            open={showInfographicsDialog}
            onClose={() => setShowInfographicsDialog(false)}
            maxWidth="lg">
            <div className="flex1 flex column infographics-container">
                <div className="flex1 flex column w100">
                    <Carousel>
                        {infographics.map((url, index) => {
                            return (
                                <div key={"infographics-" + index} className="h100 w100">
                                    <div className="infographics-img" style={{ backgroundImage: `url(${url}` }} />
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
                <div className="flex justify-end infographics-close-container">
                    <IconButton onClick={() => setShowInfographicsDialog(false)}><Close /></IconButton>
                </div>
            </div>
        </BareDialog>
    )
};

export default InfographicsDialog;