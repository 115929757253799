const ViewPager = ({currentIndex, children}) => {
    if (children && children.length) {
        children = children.filter(child => Boolean(child));
        if (currentIndex > -1 && currentIndex < children.length) {
            return children[currentIndex];
        }
    }
    return null;
};

export default ViewPager;