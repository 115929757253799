import React, {useState, useRef, useEffect} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Switch from "@material-ui/core/Switch";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import {Formik, Form} from "formik";
import {useParams, useHistory} from "react-router";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {SCHEMA as supportRecordSchema} from '@devsontap/tce-api/core/models/supportRecord';
import {PaymentMethods, LetterStatus} from '@devsontap/tce-api/core/models/enums'

import ImgSlide from "./imgSlide";
import Contribution from "./contribution";
import SupportConfirmationDialog from "./dialogs/supportConfirmation";
import SignatureDialog from "./dialogs/signatureDialog";
import ContributionConfirmationDialog from "./dialogs/contributionConfirmation";
import ContributionErrorDialog from "./dialogs/contributionError";
import StripeDialog from './dialogs/stripe';
import ViewPager from "../../_common/viewPager";
import SupportStatement from "./statement";
import SupporterInfo from "./info";
import ThankYou from "./thankYou";
import Survey from "./survey";
import InfographicsDialog from "./dialogs/infographics/infographicsDialog";
import Logo from "../../_common/logo";
import useCurrentOrg from "../../../hooks/useCurrentOrg";
import {campaignSelector} from "../../../redux/campaigns/selectors";
import {useSupportRecordDetailsSelector, useCachedRecordSelector} from "../../../redux/supportRecords/selectors";
import {useLoginSelector} from "../../../redux/login/selectors";
import {saveSupportRecord, cacheSupportRecord, clearCachedSupportRecord} from "../../../redux/supportRecords";
import {useTodoSelector} from "../../../redux/todos/selectors";
import {COLORS} from "../../../utils/markerIcons";
import {createTodo, saveTodo} from "../../../redux/todos";
import {deleteCallback} from "../../../redux/callbacks";
import {callbackSelector} from "../../../redux/callbacks/selectors";

import infoIcon from '../../../img/icn-info.svg';

import './index.css';

const CONTRIBUTION_SLIDE_NUMBER = 3;
const THANK_YOU_SLIDE_NUMBER = 4;

const SupportRecordDetails = () => {
    const cachedRecord = useCachedRecordSelector();
    const [showSupportConfirmation, setShowSupportConfirmation] = useState(false);
    const [showSignatureDialog, setShowSignatureDialog] = useState(false);
    const [showContributionConfirmation, setShowContributionConfirmation] = useState(false);
    const [showContributionError, setShowContributionError] = useState(false);
    const [showStripeDialog, setShowStripeDialog] = useState(false);
    const [showInfographicsDialog, setShowInfographicsDialog] = useState(false);
    const [showQuickAmounts, setShowQuickAmounts] = useState(false);
    const signatureRef = useRef(null);
    const {campaignId, recordId, todoId, callbackId} = useParams();
    const {campaignName, supportStatementUrl, survey, infographics, hideContributionScreen, orgId} = useSelector(campaignSelector(campaignId));
    const [currentIndex, setCurrentIndex] = useState(cachedRecord ? THANK_YOU_SLIDE_NUMBER - (hideContributionScreen ? 1 : 0) : 0);
    const supportRecord = useSupportRecordDetailsSelector(recordId);
    const history = useHistory();
    const {canvasser} = useLoginSelector();
    const dispatch = useDispatch();
    let todo = useTodoSelector(todoId);
    const callback = useSelector(callbackSelector(callbackId));
    const {enqueueSnackbar} = useSnackbar();
    const org = useCurrentOrg();

    useEffect(() => {
        if (cachedRecord) {
            dispatch(clearCachedSupportRecord());
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const goToPreviousSlide = () => setCurrentIndex(currentIndex - 1);
    const goToNextSlide = () => setCurrentIndex(currentIndex + 1);
    const goBack = () => history.goBack();
    const onLogoClick = () => currentIndex > 0 ? goToPreviousSlide() : goBack();
    const validateInfo = (values, validateForm, handleSubmit, setFieldTouched) => validateForm().then(formErrors => {
        console.log("validateInfo", formErrors);
        const allValid = ["firstName", "lastName", "houseNumber", "streetName", "address", "email"].reduce((result, field) => (
            result && !formErrors[field]
        ), true);
        allValid ? setShowSignatureDialog(true) : handleSubmit();
    });
    const validateContribution = (values, validateForm, handleSubmit, setFieldTouched) => {
        validateForm().then(errors => {
            if (errors.contribution) {
                setShowContributionError(true)
            } else if (values.contribution.amount > 0) {
                setShowContributionConfirmation(true)
            } else {
                dispatch(cacheSupportRecord(values));
                goToNextSlide();
                setFieldTouched('surveyAnswers', false);
            }
        });
    };

    const onNexts = [
        () => setShowSupportConfirmation(true),
        validateInfo,
        goToNextSlide,
        validateContribution,
        goToNextSlide,
        (values, validateForm, handleSubmit) => handleSubmit(),
    ];

    // KBM - Remove the Contribution 'onNext' if we hide that screen
    if (hideContributionScreen) {
        onNexts.splice(CONTRIBUTION_SLIDE_NUMBER, 1);
    }

    return (
        <div className="flex column h100">
            <AppBar position="static" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        <div className="flex flex1 text-align-left align-items-center">
                            <Logo onClick={onLogoClick} />
                        </div>
                        {currentIndex === CONTRIBUTION_SLIDE_NUMBER && !hideContributionScreen ?
                            <div className="flex column justify-content-center">
                                <Switch
                                    checked={showQuickAmounts}
                                    onChange={() => setShowQuickAmounts(!showQuickAmounts)}
                                    value="hide"
                                    color="secondary" />
                            </div>
                            :
                            null
                        }
                        <div>
                            <IconButton onClick={() => setShowInfographicsDialog(true)}>
                                <img src={infoIcon} alt="info" />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Formik initialValues={{
                createdAt: new Date(),
                canvasser,
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    stateCode: 'TX',
                    zip: '',
                    ...(callback?.address || {}) // Inflate the callback address into the default form values
                },
                campaignId,
                campaignName,
                orgId,
                contribution: {
                    amount: !hideContributionScreen ? '' : 0,
                    paymentMethod: PaymentMethods[0].value,
                    recurring: false
                },
                email: '',
                phone: '',
                firstName: todo && todo.supporter ? todo.supporter.firstName : '',
                lastName: todo && todo.supporter ? todo.supporter.lastName : '',
                houseNumber: callback?.houseNumber || '',
                unitNumber: callback?.address.line2 || '',
                signatureUrl: '',
                streetName: callback?.streetName || '',
                surveyAnswers: survey.map(question => ({ answer: '', question: question.questionText, required: question.required })),
                receiveTexts: false,
                todoId: todo ? todo.id : '',
                addTodo: false,
                todoColor: COLORS[0],
                todoNote: '',
                letterStatus: LetterStatus.NO,
                numLettersWritten: '',
                supporterContactId: todo && todo.supporter ? todo.supporter.id : '',
                ...cachedRecord,
                ...supportRecord
                }}
                onSubmit={values => {
                    if (values.addTodo) {
                        todo = createTodo(values.address.lat, values.address.lng, values.todoNote, values.todoColor);
                        dispatch(saveTodo(todo));
                        values.todoId = todo.id;
                    }
                    if (callbackId) {
                        dispatch(deleteCallback(callbackId, enqueueSnackbar));
                    }
                    dispatch(saveSupportRecord({
                        ...values,
                        numLettersWritten: values.numLettersWritten === "" ? 0 : values.numLettersWritten
                    }, history.goBack, true, todo, enqueueSnackbar))
                }}
                validationSchema={supportRecordSchema}>
                {({values, handleChange, setFieldValue, handleSubmit, touched, errors, validateForm, setFieldTouched}) => {
                    return (
                        <Form className="flex1 home-main-content scrollY">
                            <ViewPager currentIndex={currentIndex}>
                                <SupportStatement supportStatementUrl={supportStatementUrl} />
                                <SupporterInfo {...{values, handleChange, touched, errors, setFieldValue}} />
                                <ImgSlide url={org?.strengthInNumbers || ""} />
                                {!hideContributionScreen ?
                                    <Contribution {...{values, handleChange, touched, errors, setFieldValue, goToNextSlide, showQuickAmounts}}  />
                                    :
                                    null
                                }
                                <ThankYou hideContributionScreen={hideContributionScreen} />
                                <Survey {...{survey, values, setFieldValue, touched, errors}} />
                            </ViewPager>
                            <div className="support-record-details-next-button-container">
                                <Fab color="secondary" aria-label="Next" onClick={() => onNexts[currentIndex](values, validateForm, handleSubmit, setFieldTouched)}>
                                    <ArrowIcon htmlColor="#ffffff" />
                                </Fab>
                            </div>
                            <SupportConfirmationDialog {...{showSupportConfirmation, setShowSupportConfirmation, goToNextSlide}} />
                            <SignatureDialog {...{showSignatureDialog, setShowSignatureDialog, goToNextSlide, signatureRef, values, setFieldTouched}} />
                            <ContributionConfirmationDialog {...{showContributionConfirmation, setShowContributionConfirmation, values, setShowStripeDialog}} goToNextSlide={() => {
                                dispatch(cacheSupportRecord(values));
                                goToNextSlide();
                                setFieldTouched('surveyAnswers', false);
                            }} />
                            <ContributionErrorDialog {...{showContributionError, setShowContributionError}} />
                            <StripeDialog {...{showStripeDialog, setShowStripeDialog, handleChange, values, goToNextSlide, setFieldValue}} />
                            <InfographicsDialog {...{showInfographicsDialog, setShowInfographicsDialog, infographics}} />
                        </Form>
                    )
                }}
            </Formik>

        </div>
    )
};

export default SupportRecordDetails;