import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {useDispatch} from "react-redux";

import SignaturesPanel from "./signaturesPanel";
import {useSupportRecordsSelector} from "../../../../redux/supportRecords/selectors";
import {getCachedSignatures} from "../../../../redux/supportRecords";
import useCurrentOrg from "../../../../hooks/useCurrentOrg";

import './index.css';

const SupportStatement = ({ supportStatementUrl }) => {
    const [signatures, setSignatures] = useState(null);
    const { hiddenIds } = useSupportRecordsSelector();
    const dispatch = useDispatch();
    const org = useCurrentOrg();

    useEffect(() => {
        dispatch(getCachedSignatures(signatures => {
            setSignatures(signatures.filter(signature => !Boolean(hiddenIds[signature.id])));
        }))
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="support-statement-container flex column flex1 h100">
            <div className="support-statement-infographic-container flex column">
                <Paper className="support-statement-infographic-card padding5 flex1 flex column" square>
                    <div className="support-statement-img flex1" style={{ backgroundImage: `url(${supportStatementUrl}) `}} />
                </Paper>
            </div>
            <div className={`support-statement-signatures-container flex column${signatures && signatures.length ? "" : " hidden"}`}>
                <Paper className="support-statement-signatures-card flex1 flex column" square>
                    <div className="support-statement-signatures-header montserat">
                        Neighbors who support {org.name}
                    </div>
                    <SignaturesPanel signatures={signatures} />
                </Paper>
            </div>
        </div>
    )
};

export default SupportStatement;