import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import './index.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Standard dialog for showing prompts / alerts / messages
 * @param open      True if the dialog is open
 * @param onClose   Function for dismissing the dialog
 * @param children  Child components to render
 * @param maxWidth  MaxWidth value to pass to Dialog widget
 * TODO: Add flag to hide the Title element and maybe the Content?
 */
const BareDialog = ({open, onClose, children, maxWidth}) => (
    <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        maxWidth={maxWidth}>
        {children}
    </Dialog>
);

BareDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any,
};

BareDialog.defaultProps = {
    maxWidth: "sm"
};

export default BareDialog;