/*global google*/
import React, {useState, useRef, useEffect} from 'react'
import cx from 'classnames';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Slider from '@material-ui/core/Slider';
import Pin from '@material-ui/icons/LocationOn';
import Reset from '@material-ui/icons/Fullscreen';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Pencil from '@material-ui/icons/Edit';
import Numbers from '@material-ui/icons/FormatListNumbered';
import BarChart from '@material-ui/icons/BarChart';
import Save from '@material-ui/icons/Save';
import Redo from '@material-ui/icons/Redo';
import Undo from '@material-ui/icons/Undo';
import Brush from '@material-ui/icons/Brush';
import Close from '@material-ui/icons/Close';
import Palette from '@material-ui/icons/Palette';
import PersonAdd from '@material-ui/icons/PersonAdd';
import CallbackIcon from '@material-ui/icons/House';
import useGoogleMap from '@devsontap/dot-react-common/core/maps/useGoogleMap';
import {SketchPicker} from 'react-color';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import ToDo from './todo';
import ColorToggles from "./colorToggles";
import DrawingLayer from "./drawing";
import Clickers from "./clickers";
import TCEDialog from "../_common/dialog";
import FullscreenDialog from "../_common/dialog/fullScreen";
import SupportRecordDetails from "../supportRecords/details";
import EditSupportRecord from "../supportRecords/edit";
import Stats from "../stats";
import Callbacks from "./callbacks";
import Logo from "../_common/logo";
import useMarkerManager from "../../hooks/useMarkerManager";
import {useSupportersSelector} from "../../redux/supporters/selectors";
import {
    colorVisibilitySelector,
    lastUpdatedSelector,
    todoColorMapSelector,
    todosSelector,
} from "../../redux/todos/selectors";
import {createTodosFromSupporters, getScotyCanvasImage, saveScotyCanvasImage, createTodo, updateTodoPosition, deleteTodo, saveTodo} from "../../redux/todos";
import useComponentForAction from "../../hooks/useComponentForAction";
import {callbacksSelector} from "../../redux/callbacks/selectors";
import useCallbackMarkerManager from "../../hooks/useCallbackMarkerManager";
import {saveCallback} from "../../redux/callbacks";

import eraserOff from '../../img/eraser.png';
import eraserOn from '../../img/eraser_on.png';

import './index.css';

const mapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    scaleControl: false,
    disableDoubleClickZoom: true,
};

const Scoty = ({lat, lng, zoom}) => {
    const [drawMode, setDrawMode] = useState(false);
    const [eraseMode, setEraseMode] = useState(false);
    const [showClickers, setShowClickers] = useState(false);
    const [showColors, setShowColors] = useState(false);
    const [showPenSize, setShowPenSize] = useState(false);
    const [showColorVisibility, setShowColorVisibility] = useState(false);
    const [showToDo, setShowToDo] = useState(false);
    const [showDrawing, setShowDrawing] = useState(true);
    const [color, setColor] = useState("#000000");
    const [penSize, setPenSize] = useState(2);
    const [eraseSize, setEraseSize] = useState(5);
    const [canvasImg, setCanvasImg] = useState(null);
    const [showCallbacks, setShowCallbacks] = useState(false);
    const [callbackEditId, setCallbackEditId] = useState(null);
    const { turf, turfs, supporters } = useSupportersSelector();
    const todos = useSelector(todosSelector);
    const todoColors = useSelector(todoColorMapSelector);
    const colorVisibility = useSelector(colorVisibilitySelector);
    const lastUpdated = useSelector(lastUpdatedSelector);
    const callbacks = useSelector(callbacksSelector);
    const { enqueueSnackbar } = useSnackbar();
    const { todoId, recordId, action } = useParams();
    const actionElement = useComponentForAction(action);
    const history = useHistory();
    const dispatch = useDispatch();
    const submitRef = useRef(null);
    const redoRef = useRef(null);
    const undoRef = useRef(null);
    const clearRef = useRef(null);
    const saveRef = useRef(null);
    const map = useGoogleMap("AIzaSyCELP40X2MXGwpkpKv577DqiS2zMAgOWAQ&libraries=geometry", lat, lng, zoom, mapOptions, "map", googleMap => {
        const addTodo = (event) => dispatch(saveTodo(createTodo(event.latLng.lat(), event.latLng.lng())));
        googleMap.addListener('dblclick', addTodo);

        let currentTurfPolygon = null;

        if (turfs) {
            // Set the google map to show turf
            onResetClick(googleMap);

            // Draw all the polygons
            turfs.forEach(theTurf => {
                const polygon = new google.maps.Polygon({
                    paths: theTurf.path,
                    strokeColor: theTurf.color,
                    fillColor: theTurf.color,
                    fillOpacity: 0.3,
                    map: googleMap
                });
                polygon.addListener('dblclick', addTodo);

                if (theTurf.id === turf.id) {
                    currentTurfPolygon = polygon;
                }
            });

            const newSupporters = (supporters || [])
                .filter(supporter => !Boolean((todos || []).find(todo => todo.supporter && todo.supporter.id === supporter.id)))
                .filter(supporter => google.maps.geometry.poly.containsLocation(new google.maps.LatLng(supporter.lat, supporter.lng), currentTurfPolygon));

            if (newSupporters.length) {
                console.log("creating todos", newSupporters);
                dispatch(createTodosFromSupporters(newSupporters));
            }
        } else {
            console.log("womp womp");
        }
    });
    const markerMap = useMarkerManager(map, todos, lastUpdated,
        (marker, todoId, supporter) => {
            setShowToDo({ marker, todoId, supporter });
        },
        (todoId, lat, lng) => {
            dispatch(updateTodoPosition(todoId, lat, lng))
        });

    const callbackMarkerMap = useCallbackMarkerManager(map, callbacks, lastUpdated,
        (marker, callbackId) => {
            setShowCallbacks(true);
            setCallbackEditId(callbackId);
        },
        (callbackId, lat, lng) => {
            const callback = callbacks.find(callback => callback.id === callbackId);

            dispatch(saveCallback({
                ...callback,
                address: {
                    ...callback.address,
                    lat,
                    lng
                }
            }, () => {}, () => {}, enqueueSnackbar))
        });

    useEffect(() => {
        if (markerMap && map) {
            console.log("toggling markers");
            Object.keys(markerMap).forEach(todoId => {
                const color = todoColors[todoId];
                markerMap[todoId].setMap(colorVisibility[color] ? map : null);
            });
        }
        if (callbackMarkerMap && map) {
            Object.keys(callbackMarkerMap).forEach(callbackId => {
                callbackMarkerMap[callbackId].setMap(colorVisibility["house"] ? map : null);
            });
        }
    }, [lastUpdated, markerMap, map]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getScotyCanvasImage(img => {
            if (img) {
                setCanvasImg(img.dataUrl);
            }
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onResetClick = (googleMap) => {
        if (turf && googleMap) {
            // First, add a boundsChanged listener so we can actually set the zoom after bounds are fit, then remove after they change
            const zoomChangeBoundsListener = googleMap.addListener('bounds_changed', () => {
                google.maps.event.removeListener(zoomChangeBoundsListener);
                googleMap.setZoom(17);
            });

            const bounds = new google.maps.LatLngBounds();
            turf.path.forEach(point => bounds.extend(point));
            googleMap.fitBounds(bounds);
        }
    };

    const todoActions = [
        { label: 'Cancel', onClick: () => setShowToDo(null) },
        { label: 'Save', onClick: () => submitRef.current.click()}
    ];

    if (showToDo && !showToDo.supporter) {
        const deleteAction = {
            label: 'Delete',
            onClick: () => {
                dispatch(deleteTodo(showToDo.todoId));
                setShowToDo(null);
            }
        };
        todoActions.splice(1, 0, deleteAction )
    }

    const openCallbacks = () => {
        setShowCallbacks(true);
        setDrawMode(false);
        setShowDrawing(false);
    };

    return (
        <div className="flex column h100">
            <AppBar position="static" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        <div className="flex flex1 text-align-left align-items-center">
                            <Logo onClick={() => history.push(history.location.pathname.replace("/scoty", ""))} />
                        </div>
                        {!drawMode ?
                            <div>
                                <IconButton onClick={() => history.push(`${document.location.pathname}/add`)}>
                                    <PersonAdd htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => {
                                    setDrawMode(true);
                                    onResetClick(map);
                                }} disabled={showCallbacks}>
                                    <Pencil htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => onResetClick(map)}>
                                    <Reset htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => setShowColorVisibility(true)}>
                                    <Pin htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => setShowDrawing(!showDrawing)}>
                                    {showDrawing ?
                                        <Visibility htmlColor="#ffffff"/>
                                        :
                                        <VisibilityOff htmlColor="#ffffff"/>
                                    }
                                </IconButton>
                                <IconButton onClick={() => setShowClickers(!showClickers)}>
                                    <Numbers htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => { history.push(document.location.pathname + "/stats")}}>
                                    <BarChart htmlColor="#ffffff" />
                                </IconButton>
                            </div>
                            :
                            <div>
                                <IconButton onClick={() => setDrawMode(false)}>
                                    <Close htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => saveRef.current.click()}>
                                    <Save htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => undoRef.current.click()}>
                                    <Undo htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => redoRef.current.click()}>
                                    <Redo htmlColor="#ffffff" />
                                </IconButton>
                                <IconButton onClick={() => setShowPenSize(!showPenSize)}>
                                    <Brush htmlColor="#ffffff"/>
                                </IconButton>
                                <IconButton onClick={() => setShowColors(!showColors)}>
                                    <Palette htmlColor="#ffffff"/>
                                </IconButton>
                                <IconButton onClick={() => setEraseMode(!eraseMode)}>
                                    <img src={eraseMode ? eraserOn : eraserOff} alt="eraser" />
                                </IconButton>
                            </div>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <div className="flex flex1 relative overflow-y-scroll">
                <div className="transition-all h-full overflow-y-scroll w-[400px]">
                    <Callbacks
                        onClose={() => setShowCallbacks(false)}
                        setEditId={setCallbackEditId}
                        editId={callbackEditId}
                        centerMap={(lat, lng) => map.setCenter(new google.maps.LatLng(lat, lng))}/>
                </div>
                <div id="map" className={cx("absolute inset-0 transition-all shadow-xl", {
                    "left-[400px]": showCallbacks,
                })} />
                {!showCallbacks &&
                    <div className="absolute bottom-5 left-5">
                        <Fab color="secondary" onClick={() => openCallbacks()}>
                            <CallbackIcon />
                        </Fab>
                    </div>
                }
                <DrawingLayer {...{redoRef, undoRef, clearRef, drawMode, color, penSize, eraseSize, eraseMode, saveRef, canvasImg, showDrawing}} onSave={img => {
                    setDrawMode(false);
                    dispatch(saveScotyCanvasImage(img));
                    setCanvasImg(img);
                }} />
            </div>
            <TCEDialog
                onClose={() => setShowToDo(null)}
                open={Boolean(showToDo)}
                title=""
                showTitle={false}
                actions={todoActions}>
                <ToDo
                    todoId={showToDo ? showToDo.todoId : {}}
                    marker={showToDo ? showToDo.marker : null}
                    onClose={() => setShowToDo(null)}
                    submitRef={submitRef} />
            </TCEDialog>
            <TCEDialog
                onClose={() => setShowColorVisibility(false)}
                open={showColorVisibility}
                title=""
                showTitle={false}
                actions={[{ label: 'done', onClick: () => setShowColorVisibility(false)}]}>
                <ColorToggles colorVisibility={colorVisibility} />
            </TCEDialog>
            <TCEDialog
                onClose={() => setShowClickers(false)}
                open={showClickers}
                title="Clickers"
                actions={[{ label: 'done', onClick: () => setShowClickers(false)}]}>
                <Clickers />
            </TCEDialog>
            <TCEDialog
                onClose={() => setShowPenSize(false)}
                open={showPenSize}
                title="Brush Size"
                actions={[{ label: 'done', onClick: () => setShowPenSize(false)}]}>
                <div className="scoty-pen-size-slider">
                    <Slider
                        value={!eraseMode ? penSize : eraseSize}
                        min={1}
                        step={1}
                        max={30}
                        scale={x => x ** 10}
                        onChange={(event, newValue) => {
                            if (eraseMode) {
                                setEraseSize(newValue);
                            } else {
                                setPenSize(newValue);
                            }
                        }}
                    />
                </div>
            </TCEDialog>
            <TCEDialog
                onClose={() => setShowColors(false)}
                open={showColors}
                title=""
                showTitle={false}
                actions={[{ label: 'done', onClick: () => setShowColors(false)}]}>
                <SketchPicker
                    color={color}
                    onChangeComplete={event => setColor(event.hex)}
                    styles={{ default: { picker: { boxShadow: 'none', borderRadius: "5px", border: "1px solid #D5D5D5" } } }}/>
            </TCEDialog>
            <FullscreenDialog onClose={() => {}} open={Boolean(recordId) && !Boolean(action) && recordId !== "stats"}>
                <SupportRecordDetails recordId={recordId} todoId={todoId} />
            </FullscreenDialog>
            <FullscreenDialog onClose={() => {}} open={Boolean(recordId) && Boolean(action)}>
                <EditSupportRecord recordId={recordId} PageElement={actionElement} />
            </FullscreenDialog>
            <FullscreenDialog onClose={() => {}} open={Boolean(recordId) && recordId === "stats"}>
                <Stats />
            </FullscreenDialog>
        </div>
    )
};

Scoty.defaultProps = {
    lat: 31.2034146,
    lng: -98.418267,
    zoom: 18
};

export default Scoty;