import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import * as Yup from 'yup';
import {useDispatch} from "react-redux";

import useStripe from "../../../../../hooks/useStripe";
import {formatMoneyFromPennies} from "../../../../../utils/money";
import {createSubscription} from "../../../../../redux/supportRecords";

import stripeLogo from '../../../../../img/powered_by_stripe.png';

import './index.css';

const QUARTERLY = 3;
const MONTHLY = 1;

const StripeCCInfo = ({ values, setFieldValue, setShowStripeDialog, goToNextSlide }) => {
    const [intervalCount, setIntervalCount] = useState(MONTHLY);
    const [subscriptionEmail, setSubscriptionEmail] = useState(values.email);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [stripeError, setStripeError] = useState(null);
    const { client, card } = useStripe('#card-element', values.orgId);
    const dispatch = useDispatch();

    const { contribution } = values;

    const onSubmit = () => {
        Yup.string().email("Please enter a valid email").required().validate(subscriptionEmail)
            .then(async () => {
                setLoading(true);
                setEmailError(null);
                const result = await client.createToken(card);
                if (result.error) {
                    setStripeError(result.error.message);
                    setLoading(false);
                } else {
                    dispatch(createSubscription(subscriptionEmail, contribution.amount, result.token.id, intervalCount, values.orgId, ({response, error}) => {
                        setLoading(false);
                        if (error) {
                            setStripeError(error.message);
                        } else {
                            setFieldValue('email', subscriptionEmail, false);
                            setFieldValue('contribution', {
                                ...contribution,
                                token: result.token.id,
                                interval_count: intervalCount
                            });
                            setShowStripeDialog(false);
                            goToNextSlide();
                        }
                    }))
                }
            })
            .catch(err => setEmailError(err.message));
    };

    return (
        <div className="billing-info-container">
            <div className="flex marginBottom15">
                <ToggleButtonGroup
                    value={intervalCount}
                    name="intervalCount"
                    exclusive
                    size="small"
                    onChange={(event, interval) => setIntervalCount(interval || intervalCount)}
                    aria-label="Subscription Interval">
                    <ToggleButton value={MONTHLY} aria-label="Monthly">
                        Monthly
                    </ToggleButton>
                    <ToggleButton value={QUARTERLY} aria-label="Quarterly">
                        Quarterly
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className="montserat marginBottom5">
                How often would you like to make this {formatMoneyFromPennies(contribution.amount)} contribution?
            </div>
            {(!values.email || !values.email.length) &&
                <div className="marginBottom30">
                    <TextField
                        id="email"
                        label="Email"
                        type="email"
                        placeholder="you@gmail.com"
                        onChange={event => setSubscriptionEmail(event.target.value)}
                        value={subscriptionEmail}
                        error={Boolean(emailError)}
                        helperText={emailError ? emailError : ""}
                        fullWidth/>
                </div>
            }
            <Card className="billing-info-card marginTop30">
                <CardContent>
                    <div id="card-element" />
                </CardContent>
            </Card>
            <div className="flex justify-end marginTop10">
                <img src={stripeLogo} alt="powered by stripe" className="billing-info-stripe-img" />
            </div>
            {stripeError &&
                <div className="error-label marginTop10 montserat">
                    {stripeError}
                </div>
            }
            <div className="flex center marginTop20">
                {loading ?
                    <CircularProgress size={35} />
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSubmit}>
                        Submit
                    </Button>
                }
            </div>
        </div>
    );
};

export default StripeCCInfo;