import React, {useEffect} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import {loadOrgs} from "../../../redux/organizations";
import {orgsSelector} from "../../../redux/organizations/selectors";

import './index.css';

const OrganizationButtonGroup = ({name = "orgId", value, onChange, error, progressSize,}) => {
    const dispatch = useDispatch();
    const orgs = useSelector(orgsSelector);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(loadOrgs(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    return !orgs?.length ?
            <CircularProgress size={progressSize}/>
            :
            <ToggleButtonGroup
                value={value}
                name={name}
                exclusive
                size="medium"
                onChange={onChange}
                aria-label="Organization">
                {orgs?.map((org, index) => (
                    <ToggleButton value={org.id} aria-label={org.name} key={`org-${index}`}>
                        {org.name}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
};

export default OrganizationButtonGroup;