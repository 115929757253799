import React, {useMemo} from 'react';
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

/**
 * TODO: Show the errors in here since the TextField handles its own errors
 */
const SurveyOptions = ({question, questionIndex, updateSurveyAnswer, updateMultiChoiceSurveyAnswer, value}) => {
    const options =  useMemo(() => (
        question.choices.map((option, optionIndex) => (
            <div key={`option-${questionIndex}-${optionIndex}`} className="flex marginRight15">
                <div>
                    {question.type === "Single Choice" ?
                        <Radio
                            name={`option-${questionIndex}`}
                            checked={value === option}
                            value={option}
                            onChange={event => updateSurveyAnswer(event.target.value, questionIndex)} />
                        :
                        <Checkbox
                            value={option}
                            checked={value.indexOf(option) > -1}
                            onChange={event => updateMultiChoiceSurveyAnswer(event.target.checked, event.target.value, questionIndex)} />
                    }
                </div>
                <div className="flex center montserat survey-option-text">
                    {option}
                </div>
            </div>
        ))
    ), [value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex flexWrap">
            {options}
        </div>
    )
};

export default SurveyOptions;