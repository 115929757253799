import React, {useState, useEffect} from 'react';
import Card from "@material-ui/core/Card";
import {useDispatch} from "react-redux";

import LoginForm from "./loginForm";
import VerifyPinForm from "./verifyPinForm";
import {setRecaptchaVerifier} from "../../redux/login/actions";

import logo from '../../img/logo@2x.png';

import './index.css';

const Login = () => {
    const [verifier, setVerifier] = useState(null);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [showVerifyPin, setShowVerifyPin] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setRecaptchaVerifier('login-button',
            verifier => setVerifier(verifier),
            () => setShowVerifyPin(true)));
    }, [dispatch]);

    return (
        <div className="login-container column center">
            <div className="login-background" />
            <Card className="login-card">
                <div className="alignLeft">
                    <img src={logo} className="login-logo" alt="Texas Campaign for the Environment Logo" />
                </div>
                {!showVerifyPin ?
                    <LoginForm verifier={verifier} setConfirmationResult={setConfirmationResult} />
                    :
                    <VerifyPinForm hideVerifyPin={() => setShowVerifyPin(false)} confirmationResult={confirmationResult} />
                }
                <div id="login-button" />
            </Card>
        </div>
    )
};

export default Login;