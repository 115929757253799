import { createSelector } from '@reduxjs/toolkit';

export const campaignsLoadingSelector = ({ campaigns }) => campaigns.campaignsLoading;
export const campaignMapSelector = ({ campaigns }) => campaigns.campaignMap;
export const campaignSelector = campaignId => ({ campaigns }) => campaigns.campaignMap[campaignId];

export const campaignsSelector = createSelector(
    (campaignMapSelector),
    (campaignMap) => {
        return Object.values(campaignMap);
    }
);

export const campaignIdsSelector = createSelector(
    (campaignMapSelector),
    (campaignMap) => {
        return Object.keys(campaignMap);
    }
);

