import React, {useEffect} from 'react';
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form } from "formik";

import CustomFormikField from "../../../../_common/formik/customField";
import StateSelect from "../../../../_common/stateSelect";
import { getUsersAddress } from "../../../../../redux/supportRecords";

const CallbackDetailsForm = ({ values, setFieldValue, saving, saveRef }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!values?.streetName) {
            dispatch(getUsersAddress(results => {
                console.log("address results", results);
                setFieldValue('streetName', values.streetName || results.streetName, false);
                setFieldValue('address.line1', `${values.houseNumber} ${values.streetName || results.streetName}`, false);
                setFieldValue('address.city', values.address.city || results.city, false);
                setFieldValue('address.zip', values.address.zip || results.zip, false);
                setFieldValue('address.lat', results.lat, false);
                setFieldValue('address.lng', results.lng, false);
            }));
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form>
            <div className="mb-4">
                <CustomFormikField
                    CustomComponent={TextField}
                    name="houseNumber"
                    label="Street Number"
                    placeholder="12345"
                    onChange={e => {
                        setFieldValue('address.line1', `${e.target.value} ${values.streetName}`);
                        setFieldValue('houseNumber', e.target.value);
                    }}
                    type="tel"
                    fullWidth
                    fast />
            </div>
            <div className="mb-4">
                <CustomFormikField
                    CustomComponent={TextField}
                    name="streetName"
                    label="Street Name"
                    placeholder="Street Lane"
                    onChange={e => {
                        setFieldValue('address.line1', `${values.houseNumber} ${e.target.value}`);
                        setFieldValue('streetName', e.target.value);
                    }}
                    inputProps={{autoCapitalize: "words"}}
                    fullWidth fast />
            </div>
            <div className="mb-4">
                <CustomFormikField
                    CustomComponent={TextField}
                    name="address.line2"
                    label="Unit #"
                    placeholder="#103"
                    fullWidth fast />
            </div>
            <div className="mb-4">
                <CustomFormikField
                    CustomComponent={TextField}
                    name="address.city"
                    label="City"
                    placeholder="City Name"
                    fullWidth fast />
            </div>
            <div className="flex mb-4">
                <div className="flex-1 pr-6">
                    <StateSelect
                        name="address.stateCode"
                        onStateChanged={e => setFieldValue('address.stateCode', e.target.value)}
                        value={values.address.stateCode}
                        showLabel={true}
                        fullWidth />
                </div>
                <div className="flex-1">
                    <CustomFormikField
                        CustomComponent={TextField}
                        name="address.zip"
                        label="Zip"
                        placeholder="Zip Code"
                        type="tel"
                        inputProps={{maxLength: 5}}
                        fullWidth fast />
                </div>
            </div>
            <div className="mb-8">
                <CustomFormikField
                    CustomComponent={TextField}
                    name="notes"
                    label="Notes"
                    placeholder="Enter a note"
                    multiline
                    fullWidth fast />
            </div>
            <div className="flex justify-end">
                {!saving ?
                    <Button variant="contained" color="secondary" type="Submit" ref={saveRef}>
                        Save
                    </Button> :
                    <CircularProgress size={30} />
                }
            </div>
        </Form>
    )
};

export default CallbackDetailsForm;