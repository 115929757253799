import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";

import {campaignSelector} from "../../../redux/campaigns/selectors";
import chevron from '../../../img/icn-chevron.svg';

import './index.css';

const CampaignItem = ({ campaignId }) => {
    const campaign = useSelector(campaignSelector(campaignId));
    const { campaignName, description} = campaign;

    return (
        <Link to={`/campaigns/${campaignId}`}>
            <Card className="campaign-card">
                <CardContent>
                    <div className="flex">
                        <div className="flex1">
                            <div className="text-align-left roboto campaign-name">
                                {campaignName}
                            </div>
                            <div className="text-align-left roboto campaign-description marginTop5">
                                {description}
                            </div>
                        </div>
                        <div className="flex center">
                            <img src={chevron} alt="chevron" />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Link>
    )
};

export default CampaignItem;