import {useEffect} from 'react';
import {useDispatch} from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import {useSnackbar} from "notistack";

import {useLoginSelector} from "../../../redux/login/selectors";
import {userAuthed, userUnauthed} from "../../../redux/login/actions";
import {useSupportersSelector} from "../../../redux/supporters/selectors";

/**
 * This component is intended to use ONCE in the Application
 * @param children  The component to render when the user is Authed
 * @param unAuthed  The component to render when the user is Unauthed
 */
const AuthProvider = ({ children, unAuthed }) => {
    const { loggedIn } = useLoginSelector();
    const dispatch = useDispatch();
    const {turf, supporters} = useSupportersSelector();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                console.log("user", user);
                const token = await user.getIdTokenResult();
                console.log("claims", token.claims);
                dispatch(userAuthed(token.claims, turf, supporters, enqueueSnackbar));
            } else {
                dispatch(userUnauthed());
            }
        });
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return loggedIn ? children : unAuthed;
};

export default AuthProvider;