import {useShallowEqualSelector} from "../index";

export const useSupportRecordsSelector = () => {
    return useShallowEqualSelector(({ supportRecords: { ids, recordMap, hiddenIds, lastUpdated } }) => {
        return { ids, hiddenIds, lastUpdated, records: ids.map(id => recordMap[id]).filter(record => Boolean(record)) };
    });
};

export const useSupportRecordDetailsSelector = (recordId) => {
    return useShallowEqualSelector(({ supportRecords: { recordMap } }) => {
        let record = {};
        if (recordId !== "add") {
            record = recordMap[recordId];
        }
        return record;
    });
};

export const useCachedRecordSelector = () => {
    return useShallowEqualSelector(({ supportRecords: { cachedRecord } }) => {
        return cachedRecord;
    });
};
