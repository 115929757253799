import { createSlice } from '@reduxjs/toolkit'
import {v4 as uuidv4} from "uuid";
import {getLocal, saveLocal} from "@devsontap/dot-react-common/core/utils/localStorage";

import {COLORS} from "../../utils/markerIcons";

const KEY_TODOS = "todos";
const KEY_COLORS = "colors";

const cachedTodos = getLocal(KEY_TODOS, true) || {};

const INITIAL_STATE_NEW = {
    todoMap: cachedTodos,
    lastUpdated: null,
    colorVisibility: getLocal(KEY_COLORS, true) || COLORS.reduce((ret, color) => ({ ...ret, [color]: true }), {})
};

const todosSlice = createSlice({
    name: 'todos',
    initialState: INITIAL_STATE_NEW,
    reducers: {
        todosSuccess(state, action) {
            state.loading = false;
            state.todoMap = action.payload.reduce((ret, todo) => ({ ...ret, [todo.id]: todo }), state.todoMap);
            saveLocal(KEY_TODOS, state.todoMap, true);
        },
        todoSuccess(state, action) {
            state.todoMap[action.payload.id] = action.payload;
            state.lastUpdated = new Date().getTime();
            saveLocal(KEY_TODOS, state.todoMap, true);
        },
        deleteTodo(state, action) {
            delete state.todoMap[action.payload];
            saveLocal(KEY_TODOS, state.todoMap, true);
        },
        toggleColor(state, action) {
            state.colorVisibility[action.payload] = !state.colorVisibility[action.payload];
            state.lastUpdated = new Date().getTime();
            saveLocal(KEY_COLORS, state.colorVisibility, true);
        },
        todoDragged(state, action) {
            state.todoMap[action.payload.id].lat = action.payload.lat;
            state.todoMap[action.payload.id].lng = action.payload.lng;
            state.lastUpdated = new Date().getTime();
            saveLocal(KEY_TODOS, state.todoMap, true);
        },
        userLogout(state) {
            state.todoMap = {};
            saveLocal(KEY_TODOS, state.todoMap, true);
        }
    }
});

export const { todosSuccess, todoSuccess, deleteTodo, toggleColor, todoDragged, userLogout } = todosSlice.actions;

export default todosSlice.reducer;

// CUSTOM THUNK ACTIONS

export const createTodosFromSupporters = (supporters) => (
    (dispatch, getSate, { api }) => {
        dispatch(todosSuccess(supporters.map(supporter => ({
            id: uuidv4(),
            color: COLORS[0],
            supporter,
            note: '',
            lat: supporter.lat,
            lng: supporter.lng
        }))));
    }
);

export const createTodo = (lat, lng, note = '', color = COLORS[0]) => ({
    id: uuidv4(),
    color,
    note,
    lat,
    lng
});

export const saveTodo = (todo) => (
    (dispatch, getSate, { api }) => {
        dispatch(todoSuccess(todo));
    }
);

export const getScotyCanvasImage = (callback) => (
    async (dispatch, getSate, { api }) => {
        const dataUrl = await api.getScotyCanvasImage();
        callback(dataUrl);
    }
);

export const saveScotyCanvasImage = dataUrl => (
    async (dispatch, getSate, { api }) => {
        return api.saveScotyCanvasImage(dataUrl);
    }
);

export const updateTodoPosition = (todoId, lat, lng) => (
    (dispatch, getSate, { api }) => {
        dispatch(todoDragged({ id: todoId, lat, lng }));
    }
);


