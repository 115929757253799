import React from 'react';
import { getDefaultMiddleware, configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import 'date-fns';

import Home from './components/home';
import Login from "./components/login";
import AuthProvider from "./components/_common/authProvider";
import api from './api';
import reducers from './redux';

import './App.css';
import '@devsontap/dot-react-common/core/css/atomic.css';

/**
 * This is the Material-UI theme config for the Application
 */
const theme = createTheme({
    palette: {
        primary: {
            main: '#00b4e8',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#4db04a',
            contrastText: '#ffffff',
        },
        // error: will use the default color
    },
    typography: {
        fontFamily: [
            'Roboto, sans-serif',
            'Lato, sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        useNextVariants: true
    },
    overrides: {
        MuiButton: {
            // Name of the styleSheet
            root: {
                color: 'white'
            },

        },
        MuiFab: {
            primary: {
                backgroundColor: '#ffd400'
            }
        },
        MuiListItem: {
            button: {
                paddingTop: "0px",
                paddingBottom: "0px"
            }
        },
        MuiDialog: {
            paperWidthLg: {
                minHeight: '90vh',
                maxHeight: '90vh',
                minWidth: '90%',
                maxWidth: '90%',
            },
            paperWidthMd: {
                // minWidth: '90%',
                maxWidth: '90%',
            }
        }
    }
});

/**
 * Create a Redux store with Thunk middleware
 */
// const createStoreWithMiddleware = applyMiddleware(reduxThunk.withExtraArgument({ api }))(createStore);
// const store = createStoreWithMiddleware(reducers,
//     process.env.NODE_ENV === "development" &&
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

/**
 * Create a Redux store and configure extra argument for Thunk middleware
 */
const customizedMiddleware = getDefaultMiddleware({
    thunk: {
        extraArgument: { api }
    },
    serializableCheck: false
});

const store = configureStore({
    reducer: reducers,
    middleware: customizedMiddleware
});

Sentry.init({
    dsn: "https://2fbf24b0ced543df9cd0696f8f092fde@o392990.ingest.sentry.io/4503904258883584",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const App = () => {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <div className="App">
                        <AuthProvider unAuthed={<Login />}>
                            <Router>
                                <Home />
                            </Router>
                        </AuthProvider>
                    </div>
                </Provider>
            </MuiThemeProvider>
        </SnackbarProvider>
    );
};

export default App;
