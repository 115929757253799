import React, {useState, useEffect} from 'react';
import {Switch, Route, Redirect, Link, useHistory} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import Campaigns from "../campaigns";
import SupportRecords from '../supportRecords';
import TCEDialog from "../_common/dialog";
import Numbers from "@material-ui/icons/FormatListNumbered";
import Clickers from "../scoty/clickers";
import Logo from "../_common/logo";
import {loadAppVersion, logout} from "../../redux/login/actions";
import {useLoginSelector} from "../../redux/login/selectors";
import {loadOrgs} from "../../redux/organizations";

import mapIcon from '../../img/baseline-map-24px.svg';
import chartIcon from '../../img/baseline-bar_chart-24px.svg';

import './index.css';

const Home = () => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const [showClickers, setShowClickers] = useState(false);
    const { canvasser, appVersion } = useLoginSelector();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(loadAppVersion());
        dispatch(loadOrgs(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    return (
        <div className="home-content-container relative flex1 flex column overflow-x-scroll">
            <AppBar position="static" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        <div className="flex flex1 text-align-left align-items-center">
                            <Link to="/campaigns">
                                <Route path="/campaigns/:campaignId?" render={() => (
                                    <Logo />
                                )} />
                                {/*<img src={org?.logoUrl || logo} className="home-toolbar-logo" alt="TCE logo" />*/}
                            </Link>
                        </div>
                        <div>
                            <Route path="/campaigns/:campaignId" render={() => (
                                <>
                                    {/*<IconButton*/}
                                    {/*    aria-label="Contribution"*/}
                                    {/*    onClick={() => history.push(history.location.pathname + '/contribution')}>*/}
                                    {/*    <MoneyIcon htmlColor="#ffffff" />*/}
                                    {/*</IconButton>*/}
                                    <IconButton
                                        aria-label="Reports"
                                        onClick={() => history.push(history.location.pathname + '/stats')}>
                                        <img src={chartIcon} alt="reports" />
                                    </IconButton>
                                    <IconButton
                                        aria-label="SCOTy"
                                        onClick={() => history.push(history.location.pathname + '/scoty')}>
                                        <img src={mapIcon} alt="scoty" />
                                    </IconButton>
                                    <IconButton onClick={() => setShowClickers(!showClickers)}>
                                        <Numbers htmlColor="#ffffff" />
                                    </IconButton>
                                </>
                            )} />
                            <IconButton
                                aria-label="More"
                                aria-owns={menuAnchor ? 'long-menu' : null}
                                aria-haspopup="true"
                                onClick={event => setMenuAnchor(event.target)}
                                color="inherit">
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={menuAnchor}
                                open={Boolean(menuAnchor)}
                                onClose={() => setMenuAnchor(null)}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5,
                                        width: 250,
                                    },
                                }}>
                                {canvasser &&
                                <MenuItem>
                                    {`${canvasser.firstName} ${canvasser.lastName}`} | {appVersion}
                                </MenuItem>
                                }
                                <MenuItem onClick={() => {
                                    setShowLogoutDialog(true);
                                    setMenuAnchor(null);
                                }}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="flex1 scrollY home-main-content">
                <Switch>
                    <Route path="/campaigns" component={Campaigns} exact/>
                    <Route path={[
                        "/campaigns/:campaignId/scoty/todos/:todoId?/:recordId?/:action?",
                        "/campaigns/:campaignId/scoty/callbacks/:callbackId?/:recordId?/:action?",
                        "/campaigns/:campaignId/scoty/:recordId?/:action?",
                        "/campaigns/:campaignId/:recordId?/:action?"
                    ]} component={SupportRecords} />
                    <Route path="/" render={() => <Redirect to="/campaigns" />} />
                </Switch>
            </div>
            <TCEDialog
                onClose={() => setShowLogoutDialog(false)}
                open={showLogoutDialog}
                title="Confirm Logout"
                actions={[
                    { label: 'cancel', onClick: () => setShowLogoutDialog(false) },
                    { label: 'logout', onClick: () => dispatch(logout(history)) }
                ]}>
                Are you sure you want to log out?
            </TCEDialog>
            <TCEDialog
                onClose={() => setShowClickers(false)}
                open={showClickers}
                title="Clickers"
                actions={[{ label: 'done', onClick: () => setShowClickers(false)}]}>
                <Clickers />
            </TCEDialog>
        </div>
    )
};

export default Home;