import { createSelector } from '@reduxjs/toolkit';

export const orgMapSelector = ({ organizations }) => organizations.orgMap;
export const orgSelector = (orgId) => ({ organizations }) => organizations.orgMap[orgId];

export const orgsSelector = createSelector(
    (orgMapSelector),
    (orgMap) => {
        return Object.values(orgMap);
    }
);