import React from 'react';
import Divider from "@material-ui/core/Divider";
import SignaturePad from 'react-signature-pad';

import './index.css';

const SupportRecordSignature = ({ values, signatureRef, showError }) => {
    const { firstName, lastName, houseNumber, streetName, address, phone, email } = values;

    return (
        <div className="montserat supporter-info-confirmation-dialog-info flex column">
            <div>
                All this looks good?
            </div>
            <div className="flex marginTop15 marginBottom25">
                <div>
                    <div>
                        {`${firstName} ${lastName}`}
                    </div>
                    <div>
                        {houseNumber} {streetName}
                    </div>
                    <div>
                        {`${address.city}, ${address.stateCode} ${address.zip}`}
                    </div>
                </div>
                <div className="marginLeft30">
                    <div>
                        {phone}
                    </div>
                    <div>
                        {email}
                    </div>
                </div>
            </div>
            <div className="relative">
                <div className="montserat supporter-info-confirmation-dialog-disclaimer">
                    <div className="supporter-info-confirmation-dialog-signature-x">
                        X
                    </div>
                    <div className="marginTop5 marginLeft5">
                        <Divider />
                    </div>
                    <div className="supporter-info-confirmation-dialog-signature-label">
                        Signature
                    </div>
                </div>
                <div className="supporter-info-confirmation-dialog-signature-container flex1">
                    <SignaturePad clearButton="false" ref={signatureRef} />
                </div>
                {showError &&
                    <div className="error-label montserat marginTop10 marginLeft10">
                        Whoa there, partner. You forgot to sign.
                    </div>
                }
            </div>
            <div>

            </div>
        </div>
    )
};

export default SupportRecordSignature;